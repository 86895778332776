import React from 'react'
import { t } from 'i18next'
import { Button, Tooltip } from '@material-ui/core'
import { ShipmentToExport, Fabricant } from '../../ordreDeFabrication/models'
import api from '../api'
import { DraftAdvancedShippingNotice, OfAsn } from '../../advancedShippingNotice/models'
import { allTaillesFromGrille } from '../../common/grilleTaille'
import { getListeTraductions, ExportExcel } from './_excelHelper'

let generateDataForExcelExport = (
    asnToExport: DraftAdvancedShippingNotice,
    warehouse: string,
    manufacturer: Fabricant,
): ShipmentToExport[] => {
    let dataToSend: ShipmentToExport[] = []

    let createQuantitesBySizeTab = (ofAsn: OfAsn): { [key: string]: number } => {
        let grilleTaille = allTaillesFromGrille('')
        let quantitesBySize: { [key: string]: number } = {}

        grilleTaille.forEach(taille => {
            let quantiteAsn = ofAsn.pretAExpedier.quantites.first(x => x.taille == taille)
            quantitesBySize[taille] = quantiteAsn != null && quantiteAsn.quantite ? quantiteAsn.quantite : 0
        })

        return quantitesBySize
    }

    let buildShipmentElements = (): ShipmentToExport[] => {
        let dataToExport: ShipmentToExport[] = []

        asnToExport.ofAsns.forEach(ofAsn => {
            dataToExport.push({
                AsnNumber: '',
                AsnStatus: '',
                AsnShipDate: '',
                LastUpdate: '',
                AsnTotalShipped: asnToExport.totalPretAExpedier.toString(),
                Warehouse: warehouse,
                ManufacturerCode: manufacturer && manufacturer.code,
                ManufacturerDescription: manufacturer && manufacturer.label,
                SubActivity: ofAsn.produit.sousActivite && ofAsn.produit.sousActivite.description,
                OfNumber: ofAsn.ordreDeFabricationNumber,
                OfStatus: ofAsn.referenceProduitStatut,
                OfType: ofAsn.typeOrdreDeFabrication,
                Season: ofAsn.ordreDeFabricationSaison && ofAsn.ordreDeFabricationSaison.description,
                ModelCode: ofAsn.produit.modele && ofAsn.produit.modele.code,
                ModelDescription: ofAsn.produit.modele && ofAsn.produit.modele.description,
                MaterialCode: ofAsn.produit.matiere && ofAsn.produit.matiere.code,
                MaterialDescription: ofAsn.produit.matiere && ofAsn.produit.matiere.description,
                ColorCode: ofAsn.produit.couleur && ofAsn.produit.couleur.code,
                ColorDescription: ofAsn.produit.couleur && ofAsn.produit.couleur.description,
                Gender: ofAsn.produit.gender,
                TypeCode: ofAsn.produit.type && ofAsn.produit.type.code,
                TypeDescription: ofAsn.produit.type && ofAsn.produit.type.description,
                ProductCode: ofAsn.produit.code,
                SizeGrid: ofAsn.produit.grilleTailleCode,
                QuantitiesBySize: createQuantitesBySizeTab(ofAsn),
            })
        })
        return dataToExport
    }

    dataToSend = buildShipmentElements()

    return dataToSend
}

let exportDataToExcel = async (
    dataToMap: DraftAdvancedShippingNotice,
    warehouse: string,
    manufacturer: Fabricant,
    sheetName: string,
    fileName: string,
) => {
    let data = generateDataForExcelExport(dataToMap, warehouse, manufacturer)
    let traductions = data && data.length > 0 ? getListeTraductions(data[0]) : {}
    let excelUrl = await api.post<ExportExcel<ShipmentToExport>>(
        `excelFile/generateShipment/${fileName}/${sheetName}`,
        {
            DataToIntegrate: data,
            Traductions: traductions,
        },
    )
    window.open(`${api.apiUrl}${excelUrl.url}`)
}

let ExcelButton: (
    dataToMap: DraftAdvancedShippingNotice,
    warehouse: string,
    manufacturer: Fabricant,
    sheetName: string,
    fileName: string,
) => JSX.Element = (
    dataToMap: DraftAdvancedShippingNotice,
    warehouse: string,
    manufacturer: Fabricant,
    sheetName: string,
    fileName: string,
) => (
    <Tooltip title={t('common.exportExcel')}>
        <Button
            variant={undefined}
            color={'default'}
            onClick={() => exportDataToExcel(dataToMap, warehouse, manufacturer, sheetName, fileName)}
            style={{ padding: '0px' }}>
            <img src="/static/img/excel_icon.svg" />
        </Button>
    </Tooltip>
)

export { ExcelButton }
