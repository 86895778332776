import { observable, makeAutoObservable } from 'mobx'
import { AsnFilters } from './models'
import { AdvancedShippingNoticeView } from '../../../advancedShippingNotice/models'

class FiltersState {
    @observable filtersData: AsnFilters = new AsnFilters()
    @observable filtersShouldBeDisplayed: boolean = false
    @observable filtersShouldBeReset: boolean = false
    @observable test: boolean = false
    @observable advancedShippingNoticeInitial: AdvancedShippingNoticeView[] = []
    @observable advancedShippingNoticeTotal: AdvancedShippingNoticeView[] = []
    @observable advancedShippingNoticeToDisplay: AdvancedShippingNoticeView[] = []
    @observable advancedShippingNoticeCount: number = 0

    constructor() {
        makeAutoObservable(this)
    }
}

let asnGlobalFiltersState = new FiltersState()
export default asnGlobalFiltersState
