import { KeyValuePair } from '../filters/models'
import asnFilterHelper from './asnFilterHelper'

export enum AsnFilterTypes {
    fabricant = "Fabricant",
    numeroAsn = 'NumberAsn',
    dateExpeditionInf = 'ShippingDateFrom',
    dateExpeditionSup = 'ShippingDateTo',
    statut = 'Status',
}

export class AsnFilters {
    numeroAsn: KeyValuePair
    dateExpeditionInf: { key: Date; value: string }
    dateExpeditionSup: { key: Date; value: string }
    statut: KeyValuePair
    fabricant: KeyValuePair


    constructor() {
        asnFilterHelper.initAsnFilters(this)
    }
}
