import React from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import moment from 'moment'
import { withStyles, createStyles, Typography, Input, Divider, Tooltip } from '@material-ui/core'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { state as calendrierState } from './_calendrier'
import { ReferenceProduit, QuantiteSemaine, StatutSemaine, Semaine } from '../../models'

type PlanningValideProps = {
    referenceProduit: ReferenceProduit
    fabricantLabel: string
    backgroundColor: (week: Semaine, index: number) => string
    onFocusQuantite: (element: HTMLElement, quantiteSemaine?: QuantiteSemaine) => void
    onChangeQuantite: (element: HTMLElement, quantiteSemaine?: QuantiteSemaine) => void
    onBlurQuantite: (
        element: HTMLElement,
        referenceProduit: ReferenceProduit,
        semaine: Semaine,
        isInitial: boolean,
    ) => Promise<void>
    getQuantiteByWeekAndStatut: (
        quantiteSemaines: QuantiteSemaine[],
        week: Semaine,
        StatutSemaine: StatutSemaine,
    ) => QuantiteSemaine | undefined
}

let PlanningValide = observer((props: MuiProps & PlanningValideProps) => {
    let { referenceProduit, classes } = props

    let isPlanCellDisabled = (week: Semaine): boolean =>
        week.annee < calendrierState.currentSemaine.annee ||
        (week.annee == calendrierState.currentSemaine.annee && week.numero < calendrierState.currentSemaine.numero)

    let generateInitialTooltip = (): JSX.Element => (
        <div>
            <Typography className={classes.tooltipLine}>
                {t('planning.tooltip.validationBy')
                    .concat(t('common.celine'))
                    .concat(t('planning.tooltip.onDate'))
                    .concat(moment(props.referenceProduit.celineValidatedInitialAt).format(t('date.format.date')))}
            </Typography>

            <Typography className={classes.tooltipLine}>
                {t('planning.tooltip.validationBy')
                    .concat(props.fabricantLabel)
                    .concat(t('planning.tooltip.onDate'))
                    .concat(moment(props.referenceProduit.fabricantValidatedInitialAt).format(t('date.format.date')))}
            </Typography>
        </div>
    )

    return (
        <div className={classes.tablePlanningQuantiteParSemaine}>
            <div>
                <Tooltip title={generateInitialTooltip()} placement="top-start">
                    <div className={classes.lineTablePlanningQuantiteParSemaine}>
                        <Typography variant="subtitle1" className={classes.lineLabel}>
                            {t('planning.type.'.concat(StatutSemaine.Initial.toLowerFirstChar()))}
                        </Typography>
                        {calendrierState.listOfWeeks.map((week, index) => {
                            let quantiteSemaine = props.getQuantiteByWeekAndStatut(
                                referenceProduit.quantiteSemaines,
                                week,
                                StatutSemaine.Initial,
                            )
                            {
                                return (
                                    <Input
                                        key={index + ' initialValidatedQuantite'}
                                        value={quantiteSemaine ? quantiteSemaine.quantite : ''}
                                        className={classes.input}
                                        disabled={true}
                                        classes={{
                                            disabled: classes.innerDisabledInput,
                                            input: props.backgroundColor(week, index),
                                        }}
                                    />
                                )
                            }
                        })}
                    </div>
                </Tooltip>
            </div>
            <Divider />
            <div>
                <div className={classes.lineTablePlanningQuantiteParSemaine}>
                    <Typography variant="subtitle1" className={classes.lineLabel}>
                        {t('planning.type.'.concat(StatutSemaine.Planifie.toLowerFirstChar()))}
                    </Typography>
                    {calendrierState.listOfWeeks.map((week, index) => {
                        let quantiteSemaine = props.getQuantiteByWeekAndStatut(
                            referenceProduit.quantiteSemaines,
                            week,
                            StatutSemaine.Planifie,
                        )
                        {
                            return (
                                <Input
                                    key={
                                        index + (quantiteSemaine ? ' quantiteSemainesPlan' : ' newQuantiteSemainesPlan')
                                    }
                                    value={quantiteSemaine ? quantiteSemaine.quantite : undefined}
                                    inputProps={{ maxLength: 5 }}
                                    onFocus={e => props.onFocusQuantite(e.target, quantiteSemaine)}
                                    onChange={e => props.onChangeQuantite(e.target, quantiteSemaine)}
                                    onBlur={e => props.onBlurQuantite(e.target, referenceProduit, week, false)}
                                    className={classes.input}
                                    disabled={isPlanCellDisabled(week)}
                                    classes={{
                                        disabled: classes.innerDisabledInput,
                                        input: props.backgroundColor(week, index),
                                    }}
                                />
                            )
                        }
                    })}
                </div>
            </div>
            <Divider />
            <div>
                <div className={classes.lineTablePlanningQuantiteParSemaine}>
                    <Typography variant="subtitle1" className={classes.lineLabel}>
                        {t('planning.type.'.concat(StatutSemaine.Expedie.toLowerFirstChar()))}
                    </Typography>
                    {calendrierState.listOfWeeks.map((week, index) => {
                        let quantiteSemaine = props.getQuantiteByWeekAndStatut(
                            referenceProduit.quantiteSemaines,
                            week,
                            StatutSemaine.Expedie,
                        )
                        return (
                            <Input
                                key={index + ' quantiteSemainesShipped'}
                                value={
                                    quantiteSemaine && quantiteSemaine.quantite != null
                                        ? quantiteSemaine.quantite.toString()
                                        : ''
                                }
                                disabled
                                inputProps={{ maxLength: 5 }}
                                className={classes.input}
                                classes={{
                                    disabled: classes.innerDisabledInput,
                                    input: props.backgroundColor(week, index),
                                }}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
})

let styles = theme =>
    createStyles({
        tablePlanningQuantiteParSemaine: {
            ...defaultStyles.flexColumn,
        },
        lineLabel: {
            width: '70px',
            fontWeight: 'bold',
            color: defaultColors.grey.dark.color,
        },
        lineTablePlanningQuantiteParSemaine: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        input: {
            margin: theme.spacing(),
        },
        innerInputCurrentWeek: {
            ...defaultStyles.input,
            backgroundColor: defaultColors.primary.main.color,
            color: defaultColors.primary.main.text,
        },
        innerInputEvenWeek: {
            ...defaultStyles.input,
            backgroundColor: defaultColors.grey.light.color,
            color: defaultColors.grey.light.text,
        },
        innerInputOddWeek: {
            ...defaultStyles.input,
        },
        innerDisabledInput: {
            ...defaultStyles.disabledInput,
        },
        tooltipLine: {
            color: defaultColors.primary.dark.text,
        },
    })

export default withStyles(styles, muiOptions)(PlanningValide)
