import { Component, useRef } from 'react'
import { history } from './infrastructure/navigation'
import { MuiThemeProvider } from '@material-ui/core'
import { theme } from './infrastructure/materialUiThemeProvider'
import Shell from './infrastructure/shell/shell'
import { withNamespaces } from 'react-i18next'
import { Root } from './infrastructure/Login/Root'
import { SecureRoutes } from './infrastructure/router'
import { Security } from '@okta/okta-react'
import { useNavigate } from 'react-router-dom'
import OktaAuth from '@okta/okta-auth-js'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { oktaConfig } from '../config/oktaConfig'
import React from 'react'

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <div id="app">
                <div id="page">
                    <SecureRoutes />
                </div>
            </div>
        </MuiThemeProvider>
    )
}

export default withNamespaces(props => props.namespaces, { wait: true })(App)
