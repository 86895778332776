import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withStyles, createStyles, Typography, Input, Divider, Tooltip, Button, Fab } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { t } from 'i18next'
import api from '../../../infrastructure/api'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Profile } from '../../../infrastructure/signIn/models'
import AuthService from '../../../infrastructure/authToken'
import { state as calendrierState } from './_calendrier'
import {
    ReferenceProduit,
    QuantiteSemaine,
    StatutSemaine,
    Semaine,
    ValiderPlanningInitial,
    ValiderPlanningInitialSource,
    StatutSkus,
    AnnulerPlanningInitial,
} from '../../models'
import filterHelper from '../../../infrastructure/toolbar/filters/filterHelper'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type PlanningProps = {
    referenceProduit: ReferenceProduit
    fabricantLabel: string
    backgroundColor: (week: Semaine, index: number) => string
    onFocusQuantite: (element: HTMLElement, quantiteSemaine?: QuantiteSemaine) => void
    onChangeQuantite: (element: HTMLElement, quantiteSemaine?: QuantiteSemaine) => void
    onBlurQuantite: (
        element: HTMLElement,
        referenceProduit: ReferenceProduit,
        semaine: Semaine,
        isInitial: boolean,
    ) => Promise<void>
    getQuantiteByWeekAndStatut: (
        quantiteSemaines: QuantiteSemaine[],
        week: Semaine,
        StatutSemaine: StatutSemaine,
    ) => QuantiteSemaine | undefined
}

@observer
class PlanningInitial extends Component<MuiProps & PlanningProps> {
    userProfile: Profile

    async handleValidateInitial(userGroup: string, source: string) {
        let referenceProduit = this.props.referenceProduit

        await api.post<ValiderPlanningInitial>('ofProduit/validerInitial', {
            OrdreDeFabricationNumero: referenceProduit.ordreDeFabricationNumero,
            ProduitCode: referenceProduit.produitCode,
            UserGroup: userGroup,
            ValidationSource: source,
        })

        await filterHelper.reloadReferenceProduit(referenceProduit.ordreDeFabricationNumero, referenceProduit.id)
    }

    async handleAnnulerInitial(userGroup: string, source: string) {
        let referenceProduit = this.props.referenceProduit

        await api.post<AnnulerPlanningInitial>('ofProduit/annulerInitial', {
            OrdreDeFabricationNumero: referenceProduit.ordreDeFabricationNumero,
            ProduitCode: referenceProduit.produitCode,
            UserGroup: userGroup,
            AnnulationSource: source,
        })

        await filterHelper.reloadReferenceProduit(referenceProduit.ordreDeFabricationNumero, referenceProduit.id)
    }

    UNSAFE_componentWillMount() {
        let profile = AuthService.getAuthProfile()
        if (!!profile) this.userProfile = profile
    }

    canValidateFabricant() {
        let group = this.userProfile.group
        let isFabricant =
            !!group && (group == AuthService.GroupTypes.fabricant || group == AuthService.GroupTypes.admin)
        let fabricantAlreadyValidated = this.props.referenceProduit.fabricantValidatedInitial
        let remainToPlan = this.props.referenceProduit.quantiteStatuts.first(
            x => x.statutSku == StatutSkus.ResteAPlanifier,
        )
        let remainToPlanIsZero = !!remainToPlan && remainToPlan.quantite == 0

        return isFabricant && !fabricantAlreadyValidated && remainToPlanIsZero
    }

    canInvalidateFabricant() {
        let group = this.userProfile.group
        let isFabricant =
            !!group && (group == AuthService.GroupTypes.fabricant || group == AuthService.GroupTypes.admin)
        let fabricantAlreadyValidated = this.props.referenceProduit.fabricantValidatedInitial

        return isFabricant && fabricantAlreadyValidated
    }

    canValidateCeline() {
        let group = this.userProfile.group
        let isCeline = !!group && (group == AuthService.GroupTypes.celine || group == AuthService.GroupTypes.admin)
        let celineAlreadyValidated = this.props.referenceProduit.celineValidatedInitial
        let remainToPlan = this.props.referenceProduit.quantiteStatuts.first(
            x => x.statutSku == StatutSkus.ResteAPlanifier,
        )
        let remainToPlanIsZero = !!remainToPlan && remainToPlan.quantite == 0

        return isCeline && !celineAlreadyValidated && remainToPlanIsZero
    }

    canInvalidateCeline() {
        let group = this.userProfile.group
        let isFabricant =
            !!group && (group == AuthService.GroupTypes.fabricant || group == AuthService.GroupTypes.admin)
        let celineAlreadyValidated = this.props.referenceProduit.celineValidatedInitial

        return isFabricant && celineAlreadyValidated
    }

    render() {
        let { referenceProduit, classes } = this.props

        if (!this.userProfile || !this.userProfile.group) return <div />

        let group = this.userProfile.group
        return (
            <div className={classes.tablePlanningQuantiteParSemaine}>
                <div>
                    <div className={classes.lineTablePlanningQuantiteParSemaine}>
                        <Typography variant="subtitle1" className={classes.lineLabel}>
                            {t('planning.type.'.concat(StatutSemaine.Initial.toLowerFirstChar()))}
                        </Typography>
                        {calendrierState.listOfWeeks.map((week, index) => {
                            let quantiteSemaine = this.props.getQuantiteByWeekAndStatut(
                                referenceProduit.quantiteSemaines,
                                week,
                                StatutSemaine.Initial,
                            )
                            {
                                return (
                                    <Input
                                        key={
                                            index +
                                            (quantiteSemaine ? ' quantiteSemainesPlan' : ' newQuantiteSemainesPlan')
                                        }
                                        value={quantiteSemaine ? quantiteSemaine.quantite : undefined}
                                        inputProps={{ maxLength: 5 }}
                                        onFocus={e => this.props.onFocusQuantite(e.target, quantiteSemaine)}
                                        onChange={e => this.props.onChangeQuantite(e.target, quantiteSemaine)}
                                        onBlur={e =>
                                            this.props.onBlurQuantite(
                                                e.target as HTMLElement,
                                                referenceProduit,
                                                week,
                                                true,
                                            )
                                        }
                                        className={classes.input}
                                        disabled={
                                            week.annee <= calendrierState.currentSemaine.annee &&
                                            week.numero < calendrierState.currentSemaine.numero
                                        }
                                        classes={{
                                            input: this.props.backgroundColor(week, index),
                                        }}
                                    />
                                )
                            }
                        })}
                    </div>
                </div>
                <Divider />
                <div className={classes.validationButtonContainer}>
                    <div className={classes.validationButtonContainer}>
                        <Typography variant="subtitle1" className={classes.nameLabel}>
                            {this.props.fabricantLabel}
                        </Typography>
                        {referenceProduit.fabricantValidatedInitial ? (
                            <Icons.Check className={classes.validatedIcon} />
                        ) : (
                            <Tooltip title={t('planning.initial.validate')} placement="top">
                                <div>
                                    <Fab
                                        onClick={() =>
                                            this.handleValidateInitial(group, ValiderPlanningInitialSource.Fabricant)
                                        }
                                        disabled={!this.canValidateFabricant()}
                                        className={classes.validationButton}>
                                        <Icons.Check />
                                    </Fab>
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip title={t('planning.initial.cancel')} placement="top">
                            <div>
                                <Fab
                                    onClick={() =>
                                        this.handleAnnulerInitial(group, ValiderPlanningInitialSource.Fabricant)
                                    }
                                    disabled={!this.canInvalidateFabricant()}
                                    className={classes.annulationButton}>
                                    <Icons.Clear />
                                </Fab>
                            </div>
                        </Tooltip>
                    </div>
                    <div className={classes.validationButtonContainer}>
                        <Typography variant="subtitle1" className={classes.lineLabel}>
                            {t('common.celine')}
                        </Typography>
                        {referenceProduit.celineValidatedInitial ? (
                            <Icons.Check className={classes.validatedIcon} />
                        ) : (
                            <Tooltip title={t('planning.initial.validate')} placement="top">
                                <div>
                                    <Fab
                                        onClick={() =>
                                            this.handleValidateInitial(group, ValiderPlanningInitialSource.Celine)
                                        }
                                        disabled={!this.canValidateCeline()}
                                        className={classes.validationButton}>
                                        <Icons.Check />
                                    </Fab>
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip title={t('planning.initial.cancel')} placement="top">
                            <div>
                                <Fab
                                    onClick={() =>
                                        this.handleAnnulerInitial(group, ValiderPlanningInitialSource.Celine)
                                    }
                                    disabled={!this.canInvalidateCeline()}
                                    className={classes.annulationButton}>
                                    <Icons.Clear />
                                </Fab>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }
}

let styles = theme =>
    createStyles({
        tablePlanningQuantiteParSemaine: {
            ...defaultStyles.flexColumn,
        },
        lineLabel: {
            width: '70px',
            fontWeight: 'bold',
            color: defaultColors.grey.dark.color,
        },
        lineTablePlanningQuantiteParSemaine: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        input: {
            margin: theme.spacing(),
        },
        innerInputCurrentWeek: {
            ...defaultStyles.input,
            backgroundColor: defaultColors.primary.main.color,
            color: defaultColors.primary.main.text,
        },
        innerInputEvenWeek: {
            ...defaultStyles.input,
            backgroundColor: defaultColors.grey.light.color,
            color: defaultColors.grey.light.text,
        },
        innerInputOddWeek: {
            ...defaultStyles.input,
        },
        validationButtonContainer: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: '2%',
        },
        validationButton: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonAction as CSSProperties),
        },
        validatedIcon: {
            margin: '5px',
            color: defaultColors.green.dark.color,
        },
        annulationButton: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonAnnulation as CSSProperties),
        },
        nameLabel: {
            width: '200px',
            fontWeight: 'bold',
            textAlign: 'end',
            color: defaultColors.grey.dark.color,
        },
    })

export default withStyles(styles, muiOptions)(PlanningInitial)
