import { observer } from 'mobx-react'
import React from 'react'
import { MuiProps, muiOptions, customStyledComponent, defaultStyles } from '../materialUiThemeProvider'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { observable, makeAutoObservable, runInAction } from 'mobx'

class AdditionalFiltersState {
    @observable filtersElements: JSX.Element

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new AdditionalFiltersState()

let AdditionalFilters = observer((props: MuiProps) => {
    let classes = props.classes
    return <div className={classes.filtersContainer}>{state.filtersElements}</div>
})

let styles = theme =>
    createStyles({
        toolsContainer: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
        },
    })

export default withStyles(styles, muiOptions)(AdditionalFilters)

let replaceAdditionalFilters = (newFiltersElements?: JSX.Element) =>
    runInAction(() => (state.filtersElements = newFiltersElements ? newFiltersElements : <div />))

export { replaceAdditionalFilters }
