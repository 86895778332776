import React, { Fragment } from 'react'
import { withStyles, createStyles, Hidden, Drawer, Divider, List, ListItem } from '@material-ui/core'
import { ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, drawerWidth, defaultColors } from '../materialUiThemeProvider'
// import { navigateTo } from '../navigation'
import { t } from 'i18next'
import classnames from 'classnames'
import asnGlobalFiltersState from '../toolbar/asnFilters/asnGlobalFiltersState'
import { useNavigate } from 'react-router-dom'

type MenuProps = {
    handleDrawerToggle: VoidFunction
    mobileOpen: boolean
    version: string
}

function Menu({ classes, theme, handleDrawerToggle, mobileOpen, version }: MuiProps & MenuProps) {
    return (
        <Fragment>
            <Hidden lgUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme ? (theme.direction === 'rtl' ? 'right' : 'left') : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Note: Better open performance on mobile.
                    }}>
                    <MenuList classes={classes} version={version} toggleDrawer={handleDrawerToggle} />
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        paper: classes.drawerPaper,
                    }}>
                    <MenuList classes={classes} version={version} toggleDrawer={() => {}} />
                </Drawer>
            </Hidden>
        </Fragment>
    )
}

function MenuList({ classes, version, toggleDrawer }: MuiProps & { version: string; toggleDrawer: () => void }) {
    return (
        <Fragment>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <MenuUpperListItems classes={classes} toggleDrawer={toggleDrawer} />
            </List>
            <Divider />
            <List>
                <MenuLowerListItems />
            </List>
            <Typography variant="caption" color="inherit" className={classes.version}>
                {version}
            </Typography>
        </Fragment>
    )
}

function MenuUpperListItems({ classes, toggleDrawer }: MuiProps & { toggleDrawer: () => void }) {
    const homeClass = location.pathname === '/' ? classes.active : {}
    const planningProduitClass = location.pathname.match(/^\/planningProduit/) ? classes.active : {}
    const newAsnClass = location.pathname.match(/^\/newAsn/) ? classes.active : {}
    const listAsnClass = location.pathname.match(/^\/listAsn/) ? classes.active : {}
    const navigate = useNavigate()

    return (
        <Fragment>
            <ListItem button onClick={() => MenuNavigation('/')} className={classnames(classes.menuButton, homeClass)}>
                <ListItemIcon>
                    <Icons.ViewList />
                </ListItemIcon>
                <ListItemText primary={t('menu.manageWOs')} />
            </ListItem>
            <ListItem
                button
                onClick={() => MenuNavigation('/planningProduit')}
                className={classnames(classes.menuButton, planningProduitClass)}>
                <ListItemIcon>
                    <Icons.Today />
                </ListItemIcon>
                <ListItemText primary={t('menu.planWOs')} />
            </ListItem>
            <ListItem
                button
                onClick={() => MenuNavigation('/newAsn')}
                className={classnames(classes.menuButton, newAsnClass)}>
                <ListItemIcon>
                    <Icons.ExitToApp />
                </ListItemIcon>
                <ListItemText primary={t('menu.newAsn')} />
            </ListItem>
            <ListItem
                button
                onClick={() => MenuNavigation('/listAsn')}
                className={classnames(classes.menuButton, listAsnClass)}>
                <ListItemIcon>
                    <Icons.Receipt />
                </ListItemIcon>
                <ListItemText primary={t('menu.listAsn')} />
            </ListItem>
        </Fragment>
    )

    function MenuNavigation(path: string) {
        toggleDrawer()
        navigate(path)
    }
}

function MenuLowerListItems() {
    return isEnv('int') || isEnv('rec') ? (
        <Fragment>
            <Typography style={{ margin: '10px', color: 'red' }}>
                <b>Dear testers</b> : warning the time is 7x faster than expected! Since the 05th of november 2018, each
                day is equivalent to one week.
            </Typography>
        </Fragment>
    ) : null
}

function isEnv(environment: 'int' | 'rec' | 'uat' | 'prod') {
    return (
        document.location.hostname == 'rtwsupplierportal-' + environment + '.azurewebsites.net' ||
        document.location.hostname == 'capla' + (environment === 'prod' ? '' : '-' + environment) + '.celine.net'
    )
}

function isProd() {
    return isEnv('prod')
}

let styles = theme =>
    createStyles({
        drawerPaper: {
            width: drawerWidth,
            [theme.breakpoints.up('lg')]: {
                position: 'fixed',
            },
            color: defaultColors.primary.main.text,
            backgroundColor: defaultColors.primary.main.color,
        },
        toolbar: theme.mixins.toolbar,
        version: {
            marginTop: 'auto',
            alignSelf: 'center',
            marginBottom: '25px',
        },
        active: {
            backgroundColor: defaultColors.grey.dark.color,
        },
        menuButton: {
            '&:hover': {
                backgroundColor: '#242527',
            },
        },
    })

export default withStyles(styles, muiOptions)(Menu)
