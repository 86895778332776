import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, observe, makeAutoObservable } from 'mobx'
import {
    Button,
    Paper,
    withStyles,
    createStyles,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell as MuiTableCell,
    Tooltip,
    Fade,
    CircularProgress,
} from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { navigateTo } from '../../infrastructure/navigation'
import * as Icons from '@material-ui/icons'
import {
    MuiProps,
    muiOptions,
    customStyledComponent,
    defaultStyles,
} from '../../infrastructure/materialUiThemeProvider'
import { replaceAdditionalFilters } from '../../infrastructure/toolbar/additionalFilters'
import { replaceAdditionalTools } from '../../infrastructure/toolbar/additionalTools'
import moment from 'moment'
import { t } from 'i18next'
import asnFilterHelper from '../../infrastructure/toolbar/asnFilters/asnFilterHelper'
import asnGlobalFiltersState from '../../infrastructure/toolbar/asnFilters/asnGlobalFiltersState'
import Accumulator from '../../infrastructure/toolbar/filters/components/accumulator'
import { state as asnFilterState } from '../../infrastructure/toolbar/asnFilters/components/asnFilters'
import { state as filterState } from '../../infrastructure/toolbar/filters/components/filters'
import { toolbarState } from '../../infrastructure/toolbar/toolbar'
import { loadCompleteDataSetAsn } from '../../infrastructure/toolbar/asnFilters/asnFilterHelper'
import { AdvancedShippingNoticeView } from '../models'
import Pager, { PagerState, CreatePagerState } from '../../common/pagination/pager'
import AsnStatutChip from './asnStatutChip'
import filtersState from '../../infrastructure/toolbar/filters/filterGlobalState'
import PagerAsn, { CreatePagerAsnState } from '../../common/pagination/pagerAsn'
import { NavigateFunction } from 'react-router-dom'

type TParams = {
    navigate: NavigateFunction
}

class ListeAsnState {
    @observable pagerState: PagerState<AdvancedShippingNoticeView>
    @observable showSpinner: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new ListeAsnState()

@observer
class ListeAsn extends Component<MuiProps & TParams> {
    shouldPagersBeDisplayed: boolean = false
    numberOfItemsByPage: number = 20

    asnToDisplayObserver = observe(asnGlobalFiltersState, 'advancedShippingNoticeToDisplay', change => {
        if (filterState.filterChanged || asnFilterState.changed) {
            state.pagerState.currentPage = 0
        }
        this.initPagerState(asnGlobalFiltersState.advancedShippingNoticeToDisplay)
    })

    asnFiltersStateObserver = observe(asnFilterState, 'appliedFilters', _ => {
        this.additionalFiltersRender()
    })

    isToolbarExpendedObserver = observe(toolbarState, 'isExpended', _ => {
        this.additionalFiltersRender()
    })

    UNSAFE_componentWillMount() {
        this.initPagerState([])
    }

    async componentDidMount() {
        filtersState.isExcel = false
        filtersState.isExcelAsn = true
        let currentPageCount = Math.ceil(
            asnGlobalFiltersState.advancedShippingNoticeToDisplay.length / this.numberOfItemsByPage,
        )
        if (currentPageCount != state.pagerState.pageCount) state.pagerState.currentPage = 0
        replaceAdditionalFilters()
        replaceAdditionalTools()
        asnGlobalFiltersState.filtersShouldBeDisplayed = true
        try {
            state.showSpinner = true

            await loadCompleteDataSetAsn()
        } finally {
            state.showSpinner = false
        }
    }
    componentWillUnmount() {
        asnGlobalFiltersState.filtersShouldBeDisplayed = false
        this.asnToDisplayObserver()
        this.asnFiltersStateObserver()
        this.isToolbarExpendedObserver()
    }

    initPagerState(asnToDisplay: AdvancedShippingNoticeView[]) {
        let oldPageNumber = !!state.pagerState ? state.pagerState.currentPage : 0

        this.shouldPagersBeDisplayed = asnGlobalFiltersState.advancedShippingNoticeCount > this.numberOfItemsByPage

        state.pagerState = CreatePagerAsnState<AdvancedShippingNoticeView>(
            asnToDisplay,
            oldPageNumber,
            this.numberOfItemsByPage,
        )
    }

    additionalFiltersRender = () => {
        toolbarState.isExpended || Object.keys(asnFilterState.appliedFilters).length == 0
            ? replaceAdditionalFilters()
            : replaceAdditionalFilters(
                  <Tooltip title={t('filters.asn.accumulator')}>
                      <div style={{ height: '100%' }}>
                          <Accumulator
                              filtersAccumulated={asnFilterHelper.filtersFlat(asnFilterState.appliedFilters)}
                              getLabelForKey={asnFilterHelper.getLabelValue}
                          />
                      </div>
                  </Tooltip>,
              )
    }

    render() {
        let classes = this.props.classes
        return (
            <div>
                {this.shouldPagersBeDisplayed && (
                    <div className={classes.topPlanningPager}>
                        <PagerAsn numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} />
                    </div>
                )}
                <Fade in={state.showSpinner} unmountOnExit>
                    <CircularProgress className={classes.spinner} size={20} />
                </Fade>
                <Paper>
                    <Table className={classes.stickyTable}>
                        <TableHead>
                            <TableRow style={{ position: 'relative' }}>
                                <TableCell>
                                    <Typography variant={'h6'}>{t('asn.numero')}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.headerCell} variant={'h6'}>
                                        {t('product.manufacturer')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.headerCell} variant={'h6'}>
                                        {t('asn.shippingDate')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.headerCell} variant={'h6'}>
                                        {t('asn.quantityShipped')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.headerCell} variant={'h6'}>
                                        {t('asn.quantityReceived')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.headerCell} variant={'h6'}>
                                        {t('asn.status')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.headerCell} variant={'h6'}>
                                        {t('asn.userName')}
                                    </Typography>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!state.pagerState &&
                                state.pagerState.itemsDisplayed.map(x => (
                                    <TableRow key={x.numero}>
                                        <TableCell>
                                            <AsnStatutChip
                                                className={classes.chip}
                                                label={x.numero.pad(8)}
                                                statut={x.statut}
                                                disableTooltip={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">{x.fabricant.label}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">
                                                {moment(x.dateExpedition).format(t('date.format.date'))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">{x.totalAExpedier}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">{x.totalRecu}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <AsnStatutChip
                                                className={classes.chip}
                                                label={t('asn.statut.'.concat(x.statut.toLowerFirstChar()))}
                                                statut={x.statut}
                                                disableTooltip={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">{x.username}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant={'contained'}
                                                className={classes.buttonGoToDetails}
                                                onClick={() => this.props.navigate(`/detailsAsn/${x.numero}`)}>
                                                <Icons.YoutubeSearchedFor />
                                                {t('common.details')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Paper>
                {/* {this.shouldPagersBeDisplayed && (
                    <div className={classes.topPlanningPager}>
                        <Pager numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} />
                    </div>
                )} */}
            </div>
        )
    }
}

let TableCell = customStyledComponent(MuiTableCell)(
    {
        ...(defaultStyles.tableCell as CSSProperties),
        '&:first-child': {
            fontWeight: 'none',
            textAlign: 'center',
        },
        '&:last-child': {
            fontWeight: 'none',
            textAlign: 'center',
        },
    },
    muiOptions,
)

let styles = () =>
    createStyles({
        stickyTable: {
            '& th': {
                ...(defaultStyles.headerRow as CSSProperties),
                position: 'sticky',
                top: 130,
                zIndex: 1,
            },
        },
        headerCell: {
            ...(defaultStyles.headerCell as CSSProperties),
            '&:first-child': {
                textAlign: 'center',
            },
            '&:last-child': {
                textAlign: 'center',
            },
        },
        buttonGoToDetails: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        chip: {
            cursor: 'pointer',
            width: '100%',
        },
        spinner: {
            ...(defaultStyles.spinner as CSSProperties),
        },
    })

export default withStyles(styles, muiOptions)(ListeAsn)
