import React, { useState } from 'react'
import { observable, makeObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { createStyles, withStyles } from '@material-ui/core'
import { AppBar, Toolbar, Typography, IconButton, Tooltip } from '@material-ui/core'
import { Select, FormControl, MenuItem, Input } from '@material-ui/core'
import * as Icons from '@material-ui/icons/'
import { t } from 'i18next'
import { MuiProps, muiOptions, defaultStyles, defaultColors, CelineIcon } from '../materialUiThemeProvider'
// import { navigateTo } from '../navigation'
import authToken from '../authToken'
import { Profile, Language } from '../signIn/models'
import { changeLanguage, state as I18nextState } from '../i18nextHelper'
import { useOktaAuth } from '@okta/okta-react'
import { useNavigate } from 'react-router-dom'

const Header = (props) => {
    const { classes, handleDrawerToggle } = props
    const navigate = useNavigate()
    const { authState } = useOktaAuth();
    const [user, setUser] = useState<Profile>({
        login: '',
        firstName: '',
        lastName: '',
        mail: '',
        phone: '',
        group: '',
        fabricantCode: '',
    })

    const isAuthenticated = authState?.isAuthenticated;

    const help = () => {
        window.open(`/static/doc/help.pdf`)
    }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="Open menu"
                    onClick={handleDrawerToggle}
                    className={classes.navIconHide}>
                    <Icons.Menu />
                </IconButton>
                <img src="/static/img/logoCaplaWhite.svg" alt="Logo Capla" className={classes.caplaIcon} />
                <p className={classes.title}>CAPLA</p>
            </Toolbar>
            <CelineIcon className={classes.celineIcon} />
            {isAuthenticated &&
                !!user && (
                    <Typography variant="subtitle1" color="inherit" noWrap>
                        <Tooltip title={user.group}>
                            <span>{user.firstName.concat(' ').concat(user.lastName)}</span>
                        </Tooltip>
                        <span> | </span>
                        <FormControl>
                            <Select
                                value={I18nextState.language}
                                onChange={event => {
                                    changeLanguage((event.target.value as string) as Language)
                                    navigate('/changeLanguage')
                                }}
                                input={
                                    <Input
                                        classes={{ underline: classes.inputUnderline }}
                                        disableUnderline
                                        name="language"
                                    />
                                }
                                classes={{
                                    root: classes.selectRoot,
                                    icon: classes.selectIcon,
                                }}>
                                <MenuItem value="en">EN</MenuItem>
                                <MenuItem value="fr">FR</MenuItem>
                                <MenuItem value="it">IT</MenuItem>
                            </Select>
                        </FormControl>
                        <span> | </span>
                        <a className={classes.link} onClick={e => help()}>
                            {t('header.help')}
                        </a>
                        <span> | </span>
                    </Typography>
                )}
        </AppBar>
    )
}

function isProd() {
    return (
        document.location.hostname == 'rtwsupplierportal-prod.azurewebsites.net' ||
        document.location.hostname == 'capla.celine.net'
    )
}

let styles = theme =>
    createStyles({
        appBar: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '10px',
            position: 'fixed',
            marginLeft: 0,
            zIndex: 1201,
            backgroundColor: isProd() ? '#242527' : '#FF00FF',
        },
        title: {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '1.3125rem',
            fontWeight: 500,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: '1.1666666em',
            display: 'block',
        },
        navIconHide: {
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        link: {
            cursor: 'pointer',
        },
        selectRoot: {
            color: defaultColors.primary.main.text,
        },
        selectIcon: {
            color: defaultColors.primary.main.text,
        },
        caplaIcon: {
            margin: 10,
            width: 40,
            height: 40,
            minWidth: 40,
        },
        celineIcon: {
            position: 'absolute',
            top: '25%',
            left: 'calc(50% - 75px)',
            zIndex: -1,
        },
    })

export default withStyles(styles, muiOptions)(Header)
function usState<T>(arg0: Profile): [any, any] {
    throw new Error('Function not implemented.')
}
