import React from 'react'
import { CardMedia, CardContent, withStyles, createStyles, Typography, Tooltip } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { Modele, Couleur, Matiere, SousActivite, TypeProduit } from '../models'
import { t } from 'i18next'

type ProduitInfosProps = {
    modele: Modele
    matiere: Matiere
    couleur: Couleur
    sketch: string
    gender: string
    sousActivite?: SousActivite
    type?: TypeProduit
}

function ProduitInfos({
    modele,
    matiere,
    couleur,
    sketch,
    gender,
    sousActivite,
    type,
    classes,
}: MuiProps & ProduitInfosProps) {
    return (
        <div className={classes.card}>
            <CardMedia
                className={classes.sketch}
                component={'img'}
                src={sketch}
                title="sketch"
                onError={(e: any) => (e.target.src = '/static/img/default_sketch.jpg')}
            />
            <CardContent className={classes.flexCard}>
                <div className={classes.cardColumn}>
                    {!!modele && (
                        <div>
                            <Tooltip title={t('product.modelCode')} placement="right">
                                <Typography className={classes.code} variant="subtitle1">
                                    {modele.code}
                                </Typography>
                            </Tooltip>
                            <Tooltip title={t('product.modelDescription')} placement="right">
                                <Typography className={classes.description} variant="subtitle1">
                                    {modele.description}
                                </Typography>
                            </Tooltip>
                        </div>
                    )}
                    {!!matiere && (
                        <div>
                            <Tooltip title={t('product.materialCode')} placement="right">
                                <Typography className={classes.code} variant="subtitle1">
                                    {matiere.code}
                                </Typography>
                            </Tooltip>
                            <Tooltip title={t('product.materialDescription')} placement="right">
                                <Typography className={classes.description} variant="subtitle1">
                                    {matiere.description}
                                </Typography>
                            </Tooltip>
                        </div>
                    )}
                    {!!couleur && (
                        <div>
                            <Tooltip title={t('product.colorCode')} placement="right">
                                <Typography className={classes.code} variant="subtitle1">
                                    {couleur.code}
                                </Typography>
                            </Tooltip>
                            <Tooltip title={t('product.colorDescription')} placement="right">
                                <Typography className={classes.description} variant="subtitle1">
                                    {couleur.description}
                                </Typography>
                            </Tooltip>
                        </div>
                    )}
                    {!!gender &&
                        !!!sousActivite &&
                        !!!type && (
                            <Tooltip title={t('product.gender')} placement="right">
                                <Typography variant="subtitle1">{gender}</Typography>
                            </Tooltip>
                        )}
                </div>
                <div className={classes.cardColumn}>
                    {!!sousActivite && (
                        <Tooltip title={t('product.subActivity')} placement="right">
                            <Typography variant="subtitle1">{sousActivite.description}</Typography>
                        </Tooltip>
                    )}
                    {!!type && (
                        <Tooltip title={t('product.type')} placement="right">
                            <Typography variant="subtitle1">{type.description}</Typography>
                        </Tooltip>
                    )}
                    {!!gender &&
                        !!sousActivite &&
                        !!type && (
                            <Tooltip title={t('product.gender')} placement="right">
                                <Typography variant="subtitle1">{gender}</Typography>
                            </Tooltip>
                        )}
                </div>
            </CardContent>
        </div>
    )
}

let styles = theme =>
    createStyles({
        card: {
            ...defaultStyles.flexRow,
        },
        flexCard: {
            display: 'flex',
        },
        cardColumn: {
            flexGrow: 1,
            marginRight: '1rem',
        },
        sketch: {
            width: 120,
            height: 120,
            objectFit: 'contain',
        },
        code: {
            display: 'inline-block',
            fontWeight: 'bold',
            marginRight: '.5rem',
        },
        description: {
            display: 'inline-block',
            whiteSpace: 'nowrap',
        },
    })

export default withStyles(styles, muiOptions)(ProduitInfos)
