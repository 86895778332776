import { makeAutoObservable, observable } from 'mobx'
import api from '../infrastructure/api'
import { delay } from '../infrastructure/helper'
import { getAuthToken } from '../infrastructure/authToken'

export type GrilleTaille = {
    tailleCode: string
    grilleTailleCode: string
    ordre: number
}

class GrilleTailleState {
    @observable items: GrilleTaille[] = []
    @observable itemsByGrilleCode: { [grilleCode: string]: GrilleTaille[] } = {}

    constructor() {
        makeAutoObservable(this)
    }
}

async function loadGrilleTailles() {
    try {
        if (getAuthToken()) {
            grilleTailleState.items = await api.get<GrilleTaille[]>('grilleTailles')
            if (!!grilleTailleState.items) {
                let grilleCodes = grilleTailleState.items.map(x => x.grilleTailleCode).distinct()
                let tempItemsByGrilleCode: { [grilleCode: string]: GrilleTaille[] } = {}
                grilleCodes.forEach(
                    x => (tempItemsByGrilleCode[x] = grilleTailleState.items.filter(y => y.grilleTailleCode == x)),
                )
                grilleTailleState.itemsByGrilleCode = tempItemsByGrilleCode
            }
        } else {
            await delay(10)
            loadGrilleTailles()
        }
    } catch (ex) {
        await delay(5000)
        loadGrilleTailles()
    }
}

export function allTaillesFromGrille(grilleCode: string): string[] {
    if (grilleTailleState.itemsByGrilleCode[grilleCode]) {
        let sortFunction = (a: { taille: string; ordre: number }, b: { taille: string; ordre: number }) =>
            a.ordre < b.ordre ? -1 : a.ordre > b.ordre ? 1 : 0
        let items: { taille: string; ordre: number }[] = grilleTailleState.itemsByGrilleCode[grilleCode]
            .map(x => ({ taille: x.tailleCode, ordre: x.ordre }))
            .sort(sortFunction)
        return items.map(x => x.taille)
    } else {
        return []
    }
}

export function getOrderedTailles<T>(
    array: T[],
    tailleSelector: (value: T) => string,
    grilleTaille?: string,
): string[] {
    let tailles = array.map(tailleSelector)
    let items = allTaillesFromGrille(grilleTaille ? grilleTaille.toString().trim() : '')
    return items.filter(x => tailles.indexOf(x.toString().trim()) >= 0)
}

export let grilleTailleState = new GrilleTailleState()

loadGrilleTailles()
