import filterHelper from './filterHelper'

export enum FilterType {
    sousActivite = 'subActivity',
    saison = 'season',
    fabricant = 'manufacturer',
    magasin = 'shop',
    typeOrdreDeFabrication = 'typeWO',
    statut = 'status',
    groupage = 'group',
    numeroOrdreDeFabrication = '#WO',
    modele = 'model',
    matiere = 'material',
    couleur = 'color',
    genre = 'gender',
    type = 'type',
}

export class KeyValuePair {
    [key: string]: string
}

export class OrdreDeFabricationFilters {
    sousActivite: KeyValuePair
    saison: KeyValuePair
    fabricant: KeyValuePair
    magasin: KeyValuePair
    typeWO: KeyValuePair
    statut: KeyValuePair
    groupage: KeyValuePair
    numeroOrdreDeFabrication: KeyValuePair
    modele: KeyValuePair
    material: KeyValuePair
    couleur: KeyValuePair
    type: KeyValuePair
    genre: KeyValuePair

    constructor() {
        filterHelper.init(this)
    }
}
