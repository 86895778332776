import 'promise-polyfill/src/polyfill'
import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import './infrastructure/extensions'
import { initI18n, i18nInstance } from './infrastructure/i18nextHelper'
import { I18nextProvider } from 'react-i18next'
import jQuery from 'jquery'
import Favicon from 'react-favicon'
import { BrowserRouter as Router } from 'react-router-dom'

declare global {
    interface Window {
        jQuery: any
    }
}
window.jQuery = jQuery

if (
    !new class {
        x
    }().hasOwnProperty('x')
)
    throw new Error('Transpiler is not configured correctly')

initI18n(() =>
    ReactDOM.render(
        <I18nextProvider i18n={i18nInstance}>
            <Favicon url="/static/img/favicon.ico" />
            <Router>
                <App />
            </Router>
        </I18nextProvider>,
        document.getElementById('appContainer'),
    ),
)
