import React, { Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { createStyles, withStyles } from '@material-ui/core'
import { Button, Fab, TextField, FormControlLabel, Switch, Tooltip } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { showNotification } from '../../../infrastructure/notifications'
import api from '../../../infrastructure/api'
import { CreerCommentaire } from '../../models'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type NewCommentaireProps = {
    ordreDeFabricationNumero: string
    produitCode: string
    loadComments: Function
    cancelAddNewComment: Function
    isSuspension?: boolean
}

class NewCommentaireState {
    @observable
    newComment: {
        contenu: string
        isAlert: boolean
        isSuspension: boolean
    }

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new NewCommentaireState()

@observer
class NewCommentaire extends Component<MuiProps & NewCommentaireProps> {
    UNSAFE_componentWillMount() {
        this.resetCommentaire()
    }

    async sendCommentaire() {
        try {
            if (state.newComment.contenu.trim().length > 0) {
                await api.post<CreerCommentaire>(`commentaire/create`, {
                    OrdreDeFabricationNumero: this.props.ordreDeFabricationNumero,
                    ProduitCode: this.props.produitCode,
                    Contenu: state.newComment.contenu.trim(),
                    IsAlert: !!this.props.isSuspension || state.newComment.isAlert,
                    IsSuspension: !!this.props.isSuspension,
                })
                showNotification(t('detailsOf.commentaires.MessageAddWithSuccess'), 'success')
            }
        } catch (e) {
            showNotification(e.message, 'error')
        } finally {
            this.props.loadComments(this.props.ordreDeFabricationNumero, this.props.produitCode)
            this.resetCommentaire()
        }
    }

    cancelCommentaire() {
        this.resetCommentaire()
        this.props.cancelAddNewComment()
    }

    resetCommentaire() {
        state.newComment = {
            contenu: '',
            isAlert: false,
            isSuspension: false,
        }
    }

    render() {
        let { classes } = this.props
        return (
            <div className={!!this.props.isSuspension ? classes.suspensionBody : classes.body}>
                <TextField
                    multiline
                    rows="3"
                    rowsMax="3"
                    className={!!this.props.isSuspension ? classes.suspensionTextField : classes.textField}
                    label={t('detailsOf.commentaires.newCommentaire')}
                    value={state.newComment.contenu}
                    onChange={event => {
                        state.newComment.contenu = event.target.value
                    }}
                    inputProps={{
                        maxLength: 500,
                    }}
                />
                {!this.props.isSuspension && (
                    <Tooltip key="isAlertCommentTooltip" title={t('detailsOf.commentaires.tooltip.toggleAlert')}>
                        <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                                <Switch
                                    color="secondary"
                                    checked={state.newComment.isAlert}
                                    onChange={() => (state.newComment.isAlert = !state.newComment.isAlert)}
                                />
                            }
                            label={t('detailsOf.commentaires.alert')}
                            labelPlacement="start"
                        />
                    </Tooltip>
                )}
                <div className={classes.actionButtons}>
                    <Tooltip key="saveNewCommentTooltip" title={t('detailsOf.commentaires.tooltip.save')}>
                        <div>
                            <Fab
                                className={classes.buttonValidate}
                                onClick={() => this.sendCommentaire()}
                                disabled={state.newComment.contenu.trim().length == 0}>
                                <Icons.Check />
                            </Fab>
                        </div>
                    </Tooltip>
                    <Tooltip key="cancelAddNewCommentTooltip" title={t('detailsOf.commentaires.tooltip.cancel')}>
                        <Fab className={classes.buttonCancel} onClick={() => this.cancelCommentaire()}>
                            <Icons.Clear />
                        </Fab>
                    </Tooltip>
                </div>
            </div>
        )
    }
}

let styles = theme =>
    createStyles({
        body: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            width: '100%',
        },
        suspensionBody: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        formControlLabel: {
            marginLeft: '16px',
            marginRight: '0px',
        },
        actionButtons: {
            ...defaultStyles.flexRow,
        },
        buttonValidate: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonAction as CSSProperties),
        },
        buttonCancel: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonAnnulation as CSSProperties),
        },
        textField: {
            ...defaultStyles.textField,
            flexGrow: 1,
            marginBottom: theme.spacing(),
        },
        suspensionTextField: {
            ...defaultStyles.textField,
            width: '100%',
            marginBottom: theme.spacing(),
        },
    })

export default withStyles(styles, muiOptions)(NewCommentaire)
