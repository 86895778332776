import { observer } from 'mobx-react'
import React from 'react'
import { MuiProps, muiOptions, customStyledComponent, defaultStyles } from '../materialUiThemeProvider'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { observable, makeAutoObservable, runInAction, action, makeObservable } from 'mobx'

class AdditionalToolsState {
    toolsElements: JSX.Element = <div />

    constructor() {
        makeObservable(this, {
            toolsElements: observable.ref,
        })
    }

    @action
    replaceAdditionalTools = (newToolsElements?: JSX.Element) =>
        runInAction(() => (state.toolsElements = newToolsElements ? newToolsElements : <div />))
}

export let state = new AdditionalToolsState()

let AdditionalTools = observer((props: MuiProps) => {
    let classes = props.classes
    return <div className={classes.toolsContainer}>{state.toolsElements}</div>
})

let styles = theme =>
    createStyles({
        toolsContainer: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
        },
    })

export default withStyles(styles, muiOptions)(AdditionalTools)

let replaceAdditionalTools = (newToolsElements?: JSX.Element) =>
    runInAction(() => (state.toolsElements = newToolsElements ? newToolsElements : <div />))

export { replaceAdditionalTools }
