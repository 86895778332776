import React from 'react'
import OrdreDeFabricationSkuDetails from './details'
import { useParams } from 'react-router-dom'
import { WrappedDetails as AsnDetailsWrapped } from '../../../advancedShippingNotice/components/Wrapped'
import { state as notificationsState } from '../../../infrastructure/notifications'
import Snackbar from '../../../infrastructure/snackbar'

export const Wrapped = () => {
    const param = useParams()

    const match = {
        params: {
            numero: param.numero,
            produitCode: param.produitCode,
        },
    }

    return (
        <div>
            {match.params.numero && match.params.produitCode && <OrdreDeFabricationSkuDetails match={match} />}

            {notificationsState
                .getNotifications()
                .map(notif => <Snackbar message={notif.message} variant={notif.variant} key={notif.key} />)}
        </div>
    )
}
