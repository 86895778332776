import React, { Component, createContext } from 'react'
import { observable, makeAutoObservable, action } from 'mobx'
import moment, { Moment } from 'moment'
import Snackbar from './snackbar'

interface Notif {
    message: string
    variant: 'success' | 'warning' | 'error' | 'info'
    key: string
}

export class NotificationsState {
    notifications: Notif[] = []
    lastNotifDate?: Moment

    constructor() {
        makeAutoObservable(this)
    }

    addNotifications(newNotif: Notif) {
        this.notifications.push(newNotif)
    }

    getNotifications() {
        return this.notifications
    }
}

export let state = new NotificationsState()

export const NotificationsStpre = createContext<NotificationsState | null>(null)

export function showNotification(message: string, variant: 'success' | 'warning' | 'error' | 'info') {
    let now: Moment = moment()

    if (state.lastNotifDate) {
        if (now.diff(state.lastNotifDate, 'milliseconds') > 5000) {
            state.notifications.splice(0, state.notifications.length)
        }
    }

    state.addNotifications({
        message,
        variant,
        key: now.toString() + ' ' + now.milliseconds().toString(),
    })

    state.lastNotifDate = moment(now)
}
