import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withStyles, createStyles } from '@material-ui/core'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import ShortProductHeader from '../_shortProductHeader'
import ProduitInfos from '../_produitInfos'
import Planning from './_planning'
import QuantitesProduitParStatut from './_quantitesProduitParStatut'
import { OrdreDeFabrication, ReferenceProduit } from '../../models'

type LigneProps = {
    of: OrdreDeFabrication
    referenceProduit: ReferenceProduit
}

@observer
class Ligne extends Component<MuiProps & LigneProps> {
    render() {
        let { of, referenceProduit, classes } = this.props

        return (
            <section className={classes.line}>
                <div className={classes.ofInfos}>
                    <ShortProductHeader
                        numero={of.numero}
                        statut={referenceProduit.statut}
                        fabricantLabel={of.fabricant.label}
                        saison={of.saison}
                        typeOrdreDeFabrication={of.typeOrdreDeFabrication}
                        groupage={of.groupage}
                    />

                    <ProduitInfos
                        modele={referenceProduit.produit.modele}
                        matiere={referenceProduit.produit.matiere}
                        sketch={referenceProduit.produit.sketch}
                        couleur={referenceProduit.produit.couleur}
                        gender={referenceProduit.produit.gender}
                        sousActivite={referenceProduit.produit.sousActivite}
                        type={referenceProduit.produit.type}
                    />
                </div>

                <QuantitesProduitParStatut quantiteStatuts={referenceProduit.quantiteStatuts} />

                <Planning referenceProduit={referenceProduit} fabricant={of.fabricant.label} />
            </section>
        )
    }
}

let styles = theme =>
    createStyles({
        line: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
        },
        ofInfos: {
            width: '100%',
        },
        typo: {
            fontWeight: 'bold',
            color: defaultColors.grey.dark.color,
        },
    })

export default withStyles(styles, muiOptions)(Ligne)
