import React, { Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { withStyles, createStyles, Input } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import api from '../../../infrastructure/api'
import { showNotification } from '../../../infrastructure/notifications'
import { ReferenceProduit, QuantiteTailleStatut, AnnulerQuantiteCommandee, StatutSkus } from '../../models'
import { t } from 'i18next'

type InputUpdateQuantiteProps = {
    referenceProduit: ReferenceProduit
    tailleCode: string
    statutCode: string
    getQuantiteParTailleAndStatut(
        statutCode: string,
        taille: string,
        quantiteTailleStatuts?: QuantiteTailleStatut[],
    ): QuantiteTailleStatut | undefined
}

class InputUpdateQuantiteState {
    @observable previousValue: string

    constructor() {
        makeAutoObservable(this)
    }
}
let state = new InputUpdateQuantiteState()

@observer
class InputUpdateQuantite extends Component<MuiProps & InputUpdateQuantiteProps> {
    constructor(props) {
        super(props)
        this.onFocusQuantite = this.onFocusQuantite.bind(this)
        this.onChangeQuantite = this.onChangeQuantite.bind(this)
        this.onBlurQuantite = this.onBlurQuantite.bind(this)
    }

    onFocusQuantite(element: HTMLElement, quantiteParTaille?: QuantiteTailleStatut) {
        let targetElement = element as HTMLInputElement
        targetElement.select()
        state.previousValue =
            quantiteParTaille && quantiteParTaille.quantite ? quantiteParTaille.quantite.toString() : ''
    }

    onChangeQuantite(element: HTMLElement, quantiteParTaille?: QuantiteTailleStatut) {
        let targetElement = element as HTMLInputElement
        let [hasCorrectValue, value] = targetElement.value.tryParse()
        targetElement.value = !hasCorrectValue ? '' : value.toString()

        if (quantiteParTaille) {
            quantiteParTaille.quantite = hasCorrectValue ? value : 0
        }
    }

    async onBlurQuantite(element: HTMLElement, referenceProduit: ReferenceProduit, tailleCode: string) {
        let targetElement = element as HTMLInputElement
        let newValue = targetElement.value || ''
        let [hasCorrectValue, value]: [boolean, number] = newValue.tryParse()
        let valueChanged = state.previousValue != newValue
        let canChangeValue = this.canChangeValue(tailleCode, value, referenceProduit)
        try {
            if (valueChanged && canChangeValue && value >= 0) {
                await api.post<AnnulerQuantiteCommandee>('ofProduit/annulerQuantiteCommandee', {
                    OrdreDeFabricationNumero: referenceProduit.ordreDeFabricationNumero,
                    ProduitCode: referenceProduit.produitCode,
                    Taille: tailleCode,
                    Quantite: hasCorrectValue ? value : 0,
                })
            } else if (valueChanged && !canChangeValue) {
                showNotification(t('Message.incorrectValue'), 'error')
            } else if (hasCorrectValue && value < 0) {
                showNotification(t('Message.incorrectValue'), 'warning')
            }
        } finally {
            if (valueChanged) {
                targetElement.value = ''
                await this.reloadReferenceProduit(referenceProduit.id)
            }
        }
    }

    async reloadReferenceProduit(referenceProduitId: string) {
        let newReferenceProduit = await api.get<ReferenceProduit>(
            'ofProduit/planificationProduit/'.concat(referenceProduitId),
        )
        this.props.referenceProduit.quantiteTailleStatuts = newReferenceProduit.quantiteTailleStatuts
        this.props.referenceProduit.quantiteStatuts = newReferenceProduit.quantiteStatuts
    }

    canChangeValue(tailleCode: string, value: number, referenceProduit: ReferenceProduit) {
        let ResteAExpedierTaille = this.props.getQuantiteParTailleAndStatut(
            StatutSkus.ResteAExpedier,
            tailleCode,
            referenceProduit.quantiteTailleStatuts,
        )
        let [hasCorrectPreviousValue, previousValueNumber]: [boolean, number] = state.previousValue.tryParse()
        let quantiteTailleResteAExpedier =
            ResteAExpedierTaille && ResteAExpedierTaille.quantite ? ResteAExpedierTaille.quantite : 0

        return (
            (hasCorrectPreviousValue && value < previousValueNumber) ||
            quantiteTailleResteAExpedier + previousValueNumber >= value
        )
    }

    render() {
        let { referenceProduit, tailleCode, statutCode, classes } = this.props
        let quantiteParTaille = this.props.getQuantiteParTailleAndStatut(
            statutCode,
            tailleCode,
            referenceProduit.quantiteTailleStatuts,
        )

        return (
            <Input
                key={quantiteParTaille ? ' quantiteTailleEditable' : ' quantiteTailleCreate'}
                value={quantiteParTaille && quantiteParTaille.quantite ? quantiteParTaille.quantite : undefined}
                inputProps={{ maxLength: 5 }}
                onFocus={e => this.onFocusQuantite(e.target, quantiteParTaille)}
                onChange={e => this.onChangeQuantite(e.target, quantiteParTaille)}
                onBlur={e => this.onBlurQuantite(e.target as HTMLElement, referenceProduit, tailleCode)}
                className={classes.input}
                classes={{
                    input: classes.innerInput,
                }}
            />
        )
    }
}

let styles = theme =>
    createStyles({
        input: {
            margin: theme.spacing(),
            width: '45px',
        },
        innerInput: {
            ...defaultStyles.input,
        },
    })

export default withStyles(styles, muiOptions)(InputUpdateQuantite)
