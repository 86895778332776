import * as i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import { observable, makeAutoObservable } from 'mobx'
import { Language } from './signIn/models'

class I18nextState {
    @observable language: string = getLanguage()

    constructor() {
        makeAutoObservable(this)
    }
}

export let state = new I18nextState()

let settings: i18next.InitOptions = {
    interpolation: {
        escapeValue: false,
    },
    lng: 'en',
    fallbackLng: ['en', 'fr', 'it'],
    backend: {
        loadPath: '/static/locales/{{lng}}.json',
        allowMultiLoading: true,
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
    },
}

export let i18nInstance: i18next.i18n

function getLanguage(): string {
    let localLanguage = localStorage.getItem('language')

    return !!localLanguage ? localLanguage : 'en'
}

export function initI18n(onLoaded: (error: string) => void): void {
    let language = getLanguage()
    settings.lng = language
    state.language = language
    i18nInstance = i18next.use(XHR).init(settings, onLoaded as i18next.Callback)
}

export function changeLanguage(language: Language) {
    i18nInstance.changeLanguage(language)
    state.language = language
    localStorage.setItem('language', language)
}
