import { observable, makeAutoObservable } from 'mobx'
import { StatutSku } from './models'
import api from '../infrastructure/api'
import { delay } from '../infrastructure/helper'
import { getAuthToken } from '../infrastructure/authToken'

const listeStatutsToShowByTaille: string[] = ['Commande', 'EnTransit', 'Recu', 'Annule', 'ResteAExpedier']
const listeStatutsToShowByPlan: string[] = ['Commande', 'EnTransit', 'ResteAExpedier', 'ResteAPlanifier']

class StatutSkuState {
    @observable items: StatutSku[] = []
    @observable itemsToShowByTaille: StatutSku[] = []
    @observable itemsToShowByPlan: StatutSku[] = []

    constructor() {
        makeAutoObservable(this)
    }
}

async function loadStatutSkus() {
    try {
        if (getAuthToken()) {
            statutSkuState.items = await api.get<StatutSku[]>('ofProduit/statutSku')
            if (statutSkuState.items) {
                statutSkuState.itemsToShowByTaille = statutSkuState.items.filter(
                    x => listeStatutsToShowByTaille.indexOf(x.code) != -1,
                )
                statutSkuState.itemsToShowByPlan = statutSkuState.items.filter(
                    x => listeStatutsToShowByPlan.indexOf(x.code) != -1,
                )
            }
        } else {
            await delay(10)
            loadStatutSkus()
        }
    } catch (ex) {
        await delay(5000)
        loadStatutSkus()
    }
}

export let statutSkuState = new StatutSkuState()

loadStatutSkus()
