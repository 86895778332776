import React from 'react'
import { MuiProps, muiOptions, theme, defaultStyles } from '../../../materialUiThemeProvider'
import { Paper, createStyles, withStyles, Chip } from '@material-ui/core'
import { AsnFilterTypes } from '../../asnFilters/models'
import asnGlobalFiltersState from '../../asnFilters/asnGlobalFiltersState'
import { getPropertyNameFromFilterType } from '../../asnFilters/asnFilterHelper'

type AccumulatorProps = {
    handleDeleteFilter?: (type: string, key: string) => void
    filtersAccumulated: { type: string; key: string }[]
    getLabelForKey?: (type: string, key: string) => string
}

function Accumulator({ filtersAccumulated, handleDeleteFilter, classes, getLabelForKey }: MuiProps & AccumulatorProps) {
    // console.log('getLabelForKey', getLabelForKey('NumberAsn', '1043'))

    let getLabelValue = (type: string, key: string): string => {
        let prop = getPropertyNameFromFilterType(type as AsnFilterTypes)

        let result = asnGlobalFiltersState.filtersData[prop][key]

        return !!result ? result : key
    }

    return (
        <div className={classes.accumulatorChipContainer}>
            {filtersAccumulated.map(x => (
                <Chip
                    key={x.key.concat(x.type)}
                    label={getLabelForKey ? getLabelForKey(x.type, x.key) : getLabelValue(x.type, x.key)}
                    onDelete={handleDeleteFilter ? () => handleDeleteFilter(x.type, x.key) : undefined}
                    className={classes.filterChip}
                />
            ))}
        </div>
    )
}

let styles = () =>
    createStyles({
        accumulatorChipContainer: {
            ...defaultStyles.flexRow,
            flexWrap: 'wrap',
            alignItems: 'center',
            height: '100%',
        },
        filterChip: {
            margin: theme.spacing(0.5),
        },
    })

export default withStyles(styles, muiOptions)(Accumulator)
