import React from 'react'
import { useLocation } from 'react-router-dom'
import { createStyles, withStyles } from '@material-ui/core'
import { defaultStyles, defaultColors, muiOptions, MuiProps } from '../infrastructure/materialUiThemeProvider'
import Typography from '@material-ui/core/Typography'

const ErrorProfile = (props: MuiProps) => {
    const location = useLocation()

    return (
        <div className={props.classes.container}>
            <Typography variant="h3" className={props.classes.message}>
                {location.state.message}
            </Typography>
        </div>
    )
}

let styles = theme =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        message: {
            color: 'white',
        },
    })

export default withStyles(styles, muiOptions)(ErrorProfile)
