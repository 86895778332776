import React from 'react'
import { observer } from 'mobx-react'
import {
    Paper,
    Table,
    TableHead,
    TableRow as MuiTableRow,
    TableBody,
    TableCell as MuiTableCell,
    withStyles,
    createStyles,
} from '@material-ui/core'
import {
    MuiProps,
    muiOptions,
    customStyledComponent,
    defaultStyles,
} from '../../../infrastructure/materialUiThemeProvider'
import { QuantiteStatut } from '../../models'
import { statutSkuState } from '../../statutSku'
import { t } from 'i18next'

type DetailDynamicTableProps = {
    quantiteStatuts: QuantiteStatut[]
    isFlexGrow?: boolean
}

function getQuantiteParStatut(statutCode: string, quantiteStatuts?: QuantiteStatut[]): number {
    let quantiteStatut = quantiteStatuts && quantiteStatuts.first(e => e.statutSku == statutCode)
    return quantiteStatut && quantiteStatut.quantite ? quantiteStatut.quantite : 0
}

let QuantiteParStatutDynamicTable = observer(
    ({ classes, isFlexGrow, ...props }: MuiProps & DetailDynamicTableProps) => {
        return (
            <Paper className={classes.root} style={isFlexGrow ? { flexGrow: 1 } : {}}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            {statutSkuState.itemsToShowByTaille.map((statut, index) => (
                                <TableCell key={index + ' column header'} className={classes.headerCell}>
                                    {t('status.'.concat(statut.code.toLowerFirstChar()))}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {statutSkuState.itemsToShowByTaille.map((statut, index) => {
                                let quantiteParStatut = getQuantiteParStatut(statut.code, props.quantiteStatuts)
                                return (
                                    <TableCell
                                        key={index + ' quantiteParPhase'}
                                        className={quantiteParStatut < 0 ? classes.tableCellRed : classes.tableCell}>
                                        {quantiteParStatut.toString()}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        )
    },
)

let TableRow = customStyledComponent(MuiTableRow)(
    {
        ...defaultStyles.tableRow,
    },
    muiOptions,
)

let TableCell = customStyledComponent(MuiTableCell)(
    {
        ...defaultStyles.tableCell,
    },
    muiOptions,
)

let styles = theme =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '10px',
            overflowX: 'auto',
            ...defaultStyles.effect3D,
        },
        table: {
            width: '100%',
        },
        headerRow: {
            ...defaultStyles.headerRow,
        },
        headerCell: {
            ...defaultStyles.headerCell,
            '&:first-child': {
                textAlign: 'center',
            },
            '&:last-child': {
                textAlign: 'center',
            },
        },
        tableCell: {
            ...defaultStyles.tableCell,
            '&:first-child': {
                textAlign: 'center',
            },
            '&:last-child': {
                textAlign: 'center',
            },
        },
        tableCellRed: {
            ...defaultStyles.tableCell,
            '&:first-child': {
                textAlign: 'center',
            },
            '&:last-child': {
                textAlign: 'center',
            },
            color: 'red',
        },
    })

export default withStyles(styles, muiOptions)(QuantiteParStatutDynamicTable)
