import React, { Fragment, Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { withStyles, createStyles } from '@material-ui/core'
import {
    Table,
    TableHead,
    TableRow as MuiTableRow,
    TableCell as MuiTableCell,
    TableBody,
    Input,
    Paper,
} from '@material-ui/core'
import {
    MuiProps,
    muiOptions,
    customStyledComponent,
    defaultStyles,
} from '../../infrastructure/materialUiThemeProvider'
import ProduitInfos from './_produitInfos'
import { OfAsn, QuantiteAsn, ProgrammerExpedition, DraftAdvancedShippingNotice } from '../models'
import { showNotification } from '../../infrastructure/notifications'
import api from '../../infrastructure/api'
import { getOrderedTailles } from '../../common/grilleTaille'

type LigneProduitQuantiteAsnProps = {
    ofAsn: OfAsn
    fabricant?: string
    filteredDrafts?: DraftAdvancedShippingNotice
    magasinCode: string
    editable: boolean
    detailedView?: boolean
}

class LigneProduitQuantitesAsnState {
    @observable previousValue: string = ''

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new LigneProduitQuantitesAsnState()

@observer
class LigneProduitQuantitesAsn extends Component<MuiProps & LigneProduitQuantiteAsnProps, {}> {
    getQuantiteResteAPreparerBySize = (size: string) => {
        return this.props.ofAsn.resteAPreparer.quantites &&
            this.props.ofAsn.resteAPreparer.quantites.first(x => x.taille == size)
            ? this.props.ofAsn.resteAPreparer.quantites.first(x => x.taille == size).quantite || 0
            : 0
    }

    getQuantitePretAExpedierBySize = (size: string): QuantiteAsn | null => {
        return this.props.ofAsn.pretAExpedier.quantites
            ? this.props.ofAsn.pretAExpedier.quantites.first(x => x.taille == size)
            : null
    }

    getQuantiteRecueBySize = (size: string) => {
        return this.props.ofAsn.recu.quantites && this.props.ofAsn.recu.quantites.first(x => x.taille == size)
            ? this.props.ofAsn.recu.quantites.first(x => x.taille == size).quantite || 0
            : 0
    }

    handleInputFocus = (targetValue: any) => {
        targetValue.select()
        state.previousValue = targetValue.value
    }

    handleInputChange = (targetValue: any, quantiteAsnToChange: QuantiteAsn | null) => {
        let [hasCorrectValue, value] = targetValue.value.tryParse()
        targetValue.value = hasCorrectValue ? value : ''

        if (!!quantiteAsnToChange) {
            quantiteAsnToChange.quantite = hasCorrectValue ? value : null
        }
    }

    handleInputBlur = async (ofAsn: OfAsn, taille: string, targetValue: any) => {
        let [hasCorrectValue, value] = targetValue.value.tryParse()
        let valueChanged = targetValue.value != state.previousValue

        try {
            if (valueChanged && value >= 0) {
                await api.post<ProgrammerExpedition>(
                    `asn/${this.props.fabricant ? this.props.fabricant : ''}/draft/programmer`,
                    {
                        OrdreDeFabricationNumero: ofAsn.ordreDeFabricationNumber,
                        ProduitCode: ofAsn.produit.code,
                        Taille: taille,
                        MagasinCode: this.props.magasinCode,
                        Quantite: hasCorrectValue ? value : null,
                    },
                )
            } else if ((hasCorrectValue && value < 0) || (!hasCorrectValue && state.previousValue != '')) {
                showNotification(t('Message.incorrectValue'), 'warning')
            }
        } catch (e) {
            showNotification(e.message, 'error')
        } finally {
            if (valueChanged) {
                await this.updateQuantitesProduit(ofAsn.referenceProduitId, taille)
            }
        }
    }

    updateQuantitesProduit = async (referenceProduitId: string, taille: string) => {
        let ofAsn = await api.get<OfAsn>(
            `asn/${this.props.fabricant ? this.props.fabricant : ''}/${referenceProduitId}/${
                this.props.magasinCode
            }/draftProduit`,
        )
        this.props.ofAsn.pretAExpedier.total = ofAsn.pretAExpedier.total
        if (
            !!this.props.ofAsn.pretAExpedier.quantites &&
            this.props.ofAsn.pretAExpedier.quantites.first(x => x.taille == taille)
        ) {
            let newValueOfPretAExpedier = ofAsn.pretAExpedier.quantites
                ? ofAsn.pretAExpedier.quantites.first(x => x.taille == taille)
                : null
            this.props.ofAsn.pretAExpedier.quantites.first(x => x.taille == taille).quantite = newValueOfPretAExpedier
                ? newValueOfPretAExpedier.quantite
                : null
        }

        this.props.ofAsn.resteAPreparer.total = ofAsn.resteAPreparer.total
        this.props.ofAsn.resteAPreparer.quantites = ofAsn.resteAPreparer.quantites
        if (this.props.filteredDrafts) this.props.filteredDrafts.totalPretAExpedier = ofAsn.draftTotalPretAExpedier
    }

    render() {
        let { ofAsn, classes, editable, detailedView } = this.props
        let sizes = ofAsn.resteAPreparer.quantites.map(x => x.taille)
        let orderedTailles = getOrderedTailles(sizes, x => x.toString().trim(), ofAsn.produit.grilleTailleCode)
        return (
            <Fragment>
                <Paper className={classes.lineRoot}>
                    <div className={classes.productInfo}>
                        <ProduitInfos ofAsn={ofAsn} />
                    </div>
                    <Paper className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.headerRow}>
                                    <TableCell className={classes.headerCell}>{t('common.size')}</TableCell>
                                    {orderedTailles.map((taille, index) => (
                                        <TableCell key={index + ' column header'} className={classes.headerCell}>
                                            {taille}
                                        </TableCell>
                                    ))}
                                    <TableCell className={classes.headerCell}>{t('common.total')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>{t('status.remainToPrepare')}</TableCell>
                                    {orderedTailles.map(x => {
                                        let quantiteResteAPreparer = this.getQuantiteResteAPreparerBySize(x)
                                        return (
                                            <TableCell
                                                className={
                                                    quantiteResteAPreparer >= 0
                                                        ? classes.tableCell
                                                        : classes.tableCellRed
                                                }
                                                key={`Remain to prepare ${x}`}>
                                                {quantiteResteAPreparer}
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell className={classes.tableCell}>{ofAsn.resteAPreparer.total}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        {!!detailedView ? t('status.shipped') : t('status.readyToShip')}
                                    </TableCell>
                                    {orderedTailles.map((x, index) => {
                                        let quantiteResteAPreparer = this.getQuantiteResteAPreparerBySize(x)
                                        let quantitePretAExpedier = this.getQuantitePretAExpedierBySize(x)
                                        let quantite =
                                            quantitePretAExpedier && quantitePretAExpedier.quantite
                                                ? quantitePretAExpedier.quantite
                                                : null
                                        return (
                                            <TableCell className={classes.tableCell} key={`Ready to ship ${x}`}>
                                                {editable ? (
                                                    <Input
                                                        key={
                                                            index +
                                                            (quantitePretAExpedier
                                                                ? ' quantitePretAExpedier'
                                                                : ' newQuantitePretAExpedier')
                                                        }
                                                        value={quantite ? quantite : undefined}
                                                        className={classes.input}
                                                        inputProps={{ maxLength: 5 }}
                                                        classes={{
                                                            input:
                                                                quantiteResteAPreparer >= 0
                                                                    ? classes.innerInput
                                                                    : classes.innerInputRed,
                                                        }}
                                                        onFocus={event => this.handleInputFocus(event.target)}
                                                        onChange={event =>
                                                            this.handleInputChange(event.target, quantitePretAExpedier)
                                                        }
                                                        onBlur={event => this.handleInputBlur(ofAsn, x, event.target)}
                                                    />
                                                ) : (
                                                    quantite
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell className={classes.tableCell}>{ofAsn.pretAExpedier.total}</TableCell>
                                </TableRow>
                                {!editable && (
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>{t('status.recu')}</TableCell>
                                        {orderedTailles.map(x => {
                                            let quantiteRecu = this.getQuantiteRecueBySize(x)
                                            return (
                                                <TableCell
                                                    className={
                                                        quantiteRecu >= 0 ? classes.tableCell : classes.tableCellRed
                                                    }
                                                    key={`Recu ${x}`}>
                                                    {quantiteRecu}
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell className={classes.tableCell}>{ofAsn.recu.total}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </Paper>
            </Fragment>
        )
    }
}

let TableRow = customStyledComponent(MuiTableRow)(
    {
        ...defaultStyles.tableRow,
    },
    muiOptions,
)

let TableCell = customStyledComponent(MuiTableCell)(
    {
        ...defaultStyles.tableCell,
    },
    muiOptions,
)

let styles = theme =>
    createStyles({
        lineRoot: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            margin: '5px 0px',
            overflowX: 'auto',
        },
        productInfo: {
            width: '35%',
        },
        tableContainer: {
            width: '65%',
            ...defaultStyles.effect3D,
        },
        input: {
            margin: theme.spacing(),
        },
        innerInput: {
            ...defaultStyles.input,
        },
        innerInputRed: {
            ...defaultStyles.inputError,
        },
        headerRow: {
            ...defaultStyles.headerRow,
        },
        headerCell: {
            ...defaultStyles.headerCell,
        },
        tableCell: {
            ...defaultStyles.tableCell,
        },
        tableCellRed: {
            ...defaultStyles.tableCell,
            color: 'red',
        },
    })

export default withStyles(styles, muiOptions)(LigneProduitQuantitesAsn)
