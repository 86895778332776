import React, { Component } from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { Stepper as MuiStepper, Step, StepLabel, Typography } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import * as Icons from '@material-ui/icons'
import { Steps } from '../models'
import { t } from 'i18next'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

type StepperProps = {
    activeStep: number
}

class Stepper extends Component<MuiProps & StepperProps> {
    getSteps(): { number: Steps; label: string }[] {
        return [
            {
                number: Steps.Prepare,
                label: t('asn.stepper.prepare'),
            },
            {
                number: Steps.Checking,
                label: t('asn.stepper.checking'),
            },
            {
                number: Steps.Created,
                label: t('asn.stepper.created'),
            },
        ]
    }

    getIcon(step: Steps): React.ComponentType<SvgIconProps> {
        switch (step) {
            case Steps.Prepare:
                return Icons.PlaylistAdd
            case Steps.Checking:
                return Icons.PlaylistAddCheck
            case Steps.Created:
                return Icons.LocalShipping
            default:
                return Icons.PlaylistAdd
        }
    }

    render() {
        const { classes, activeStep } = this.props
        const steps = this.getSteps()

        return (
            <div className={classes.root}>
                <MuiStepper classes={{ root: classes.stepperRoot }} activeStep={activeStep} alternativeLabel>
                    {steps.map(step => {
                        let Icon = this.getIcon(step.number)
                        return (
                            <Step key={step.number}>
                                <StepLabel className={classes.stepLabel}>
                                    <Icon />
                                    <Typography variant="subtitle1">{step.label}</Typography>
                                </StepLabel>
                            </Step>
                        )
                    })}
                </MuiStepper>
                <div />
            </div>
        )
    }
}

let styles = theme =>
    createStyles({
        root: {
            width: '100%',
        },
        stepperRoot: {
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            padding: '15px 0px',
        },
        stepLabel: {
            ...defaultStyles.flexColumn,
        },
    })

export default withStyles(styles, muiOptions)(Stepper)
