import React from 'react'
import { createStyles, withStyles, Tooltip } from '@material-ui/core'
import { Typography, Card, CardMedia, CardContent } from '@material-ui/core'
import {
    MuiProps,
    muiOptions,
    defaultColors,
    defaultStyles,
    borderStyle,
} from '../../../infrastructure/materialUiThemeProvider'
import { Produit } from '../../models'
import { t } from 'i18next'

type ProduitInfosProps = {
    produit: Produit
}

function ProduitInfos({ produit, classes }: MuiProps & ProduitInfosProps) {
    return (
        <Card className={classes.card}>
            <CardMedia
                className={classes.cardMedia}
                component="img"
                src={produit.sketch}
                title="Sketch"
                onError={(e: any) => (e.target.src = '/static/img/default_sketch.jpg')}
            />
            <CardContent className={classes.cardContent}>
                {!!produit.modele && (
                    <div>
                        <Tooltip title={t('product.modelCode')} placement="right">
                            <Typography className={classes.code} variant="subtitle1">
                                {produit.modele.code}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={t('product.modelDescription')} placement="right">
                            <Typography className={classes.description} variant="subtitle1">
                                {produit.modele.description}
                            </Typography>
                        </Tooltip>
                    </div>
                )}
                {!!produit.matiere && (
                    <div>
                        <Tooltip title={t('product.materialCode')} placement="right">
                            <Typography className={classes.code} variant="subtitle1">
                                {produit.matiere.code}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={t('product.materialDescription')} placement="right">
                            <Typography className={classes.description} variant="subtitle1">
                                {produit.matiere.description}
                            </Typography>
                        </Tooltip>
                    </div>
                )}
                {!!produit.couleur && (
                    <div>
                        <Tooltip title={t('product.colorCode')} placement="right">
                            <Typography className={classes.code} variant="subtitle1">
                                {produit.couleur.code}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={t('product.colorDescription')} placement="right">
                            <Typography className={classes.description} variant="subtitle1">
                                {produit.couleur.description}
                            </Typography>
                        </Tooltip>
                    </div>
                )}
                <Tooltip title={t('product.gender')} placement="left">
                    <Typography variant={'subtitle1'}>{produit.gender}</Typography>
                </Tooltip>
                <Tooltip title={t('product.subActivity')} placement="left">
                    <Typography variant={'subtitle1'}>
                        {!!produit.sousActivite ? produit.sousActivite.description : ''}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('product.type')} placement="left">
                    <Typography variant={'subtitle1'}>{!!produit.type ? produit.type.description : ''}</Typography>
                </Tooltip>
                <Typography variant={'subtitle1'} className={classes.typoSku}>
                    {produit.code}
                </Typography>
            </CardContent>
        </Card>
    )
}

let styles = theme =>
    createStyles({
        card: {
            width: '35%',
            margin: '10px',
        },
        cardMedia: {
            width: '100%',
            height: '330px',
            objectFit: 'contain',
            margin: '10px 0px',
        },
        cardContent: {
            ...defaultStyles.flexColumn,
            backgroundColor: defaultColors.grey.light.color,
            color: defaultColors.grey.light.text,
            borderTop: borderStyle,
            height: '100%',
        },
        line: {
            ...defaultStyles.flexRow,
        },
        code: {
            display: 'inline-block',
            fontWeight: 'bold',
            marginRight: '.5rem',
        },
        description: {
            display: 'inline-block',
        },
        typoSku: {
            fontWeight: 'bold',
            color: defaultColors.grey.dark.color,
        },
    })

export default withStyles(styles, muiOptions)(ProduitInfos)
