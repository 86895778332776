//@ts-nocheck
import React from 'react'
import ListeAsn from './listeAsn'
import { useNavigate, useParams } from 'react-router-dom'
import Details from './details'
import { state as notificationsState } from '../../infrastructure/notifications'
import Snackbar from '../../infrastructure/snackbar'

export const Wrapped = () => {
    const navigate = useNavigate()

    return (
        <div>
            <ListeAsn navigate={navigate} />
        </div>
    )
}

export const WrappedDetails = () => {
    const param = useParams()

    const match = { params: { numero: param.numero } }

    return (
        <div>
            <Details match={match} />
            {notificationsState
                .getNotifications()
                .map(notif => <Snackbar message={notif.message} variant={notif.variant} key={notif.key} />)}
        </div>
    )
}
