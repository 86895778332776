import React, { Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { createStyles, withStyles } from '@material-ui/core'
import {
    Badge,
    Typography,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Fab,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Commentaire } from '../../models'
import LigneCommentaire from './ligne'
import NewCommentaire from './create'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type CommentairesProps = {
    ordreDeFabricationNumero: string
    produitCode: string
    loadComments: (ordreDeFabricationNumber: string, produitCode: string) => void
}

class CommentairesState {
    @observable addNewComment: boolean = false
    @observable comments: Commentaire[]
    @observable nbAlerts: number = 0
    @observable hasAlert: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new CommentairesState()

@observer
class Commentaires extends Component<MuiProps & CommentairesProps> {
    async componentDidMount() {
        await this.props.loadComments(this.props.ordreDeFabricationNumero, this.props.produitCode)
    }

    render() {
        let { classes } = this.props
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Tooltip key="nbrCommentsTooltip" title={t('detailsOf.commentaires.tooltip.numberOfComments')}>
                        <Badge
                            color={state.hasAlert ? 'secondary' : 'primary'}
                            badgeContent={state.nbAlerts}
                            className={classes.badge}>
                            <Typography variant={'subtitle1'} className={classes.typoHeader}>
                                {t('detailsOf.commentaires.label')}
                            </Typography>
                        </Badge>
                    </Tooltip>
                    <Tooltip key="addNewCommentTooltip" title={t('detailsOf.commentaires.tooltip.addComment')}>
                        <div>
                            <Fab
                                className={classes.buttonAdd}
                                onClick={() => (state.addNewComment = true)}
                                disabled={state.addNewComment}>
                                <Icons.Add />
                            </Fab>
                        </div>
                    </Tooltip>
                </div>

                {state.addNewComment && (
                    <NewCommentaire
                        ordreDeFabricationNumero={this.props.ordreDeFabricationNumero}
                        produitCode={this.props.produitCode}
                        loadComments={this.props.loadComments}
                        cancelAddNewComment={() => (state.addNewComment = false)}
                    />
                )}

                {state.comments && (
                    <Paper className={classes.tableRoot}>
                        <Table className={classes.table}>
                            <TableBody>
                                {state.comments.map((comment, index) => {
                                    return (
                                        <TableRow key={index + ' comment'}>
                                            <TableCell component="th" scope="row">
                                                <LigneCommentaire
                                                    ordreDeFabricationNumero={this.props.ordreDeFabricationNumero}
                                                    produitCode={this.props.produitCode}
                                                    comment={comment}
                                                    loadComments={this.props.loadComments}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                )}
            </div>
        )
    }
}

let styles = theme =>
    createStyles({
        root: {
            ...defaultStyles.flexColumn,
            margin: '10px',
        },
        header: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        badge: {
            margin: theme.spacing(2),
        },
        typoHeader: {
            padding: `0 ${theme.spacing(2)}px`,
        },
        body: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        buttonAdd: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        tableRoot: {
            width: '100%',
            maxHeight: '300px',
            overflowX: 'auto',
        },
    })

export default withStyles(styles, muiOptions)(Commentaires)

export { state }
