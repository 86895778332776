import React from 'react'
import { withStyles, createStyles, Typography } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { OfAsn } from '../models'
import { t } from 'i18next'
import OfStatutChip from '../../common/ofStatutChip'

type ProduitInfosProps = {
    ofAsn: OfAsn
}

function ProduitInfos({ ofAsn, classes }: MuiProps & ProduitInfosProps) {
    return (
        <div className={classes.produitCard}>
            <div className={classes.chipAndSketch}>
                <OfStatutChip statut={ofAsn.referenceProduitStatut} label={ofAsn.ordreDeFabricationNumber} />
                <img
                    className={classes.sketch}
                    src={ofAsn.produit.sketch}
                    onError={(e: any) => (e.target.src = '/static/img/default_sketch.jpg')}
                />
            </div>
            <div>
                <Typography noWrap variant="subtitle1">
                    {!!ofAsn.ordreDeFabricationSaison ? ofAsn.ordreDeFabricationSaison.description : ''}
                </Typography>
                <Typography noWrap variant="subtitle1">
                    {!!ofAsn.typeOrdreDeFabrication && t('typeOf.'.concat(ofAsn.typeOrdreDeFabrication.toLowerCase()))}
                </Typography>
                <Typography noWrap variant="subtitle1">
                    {!!ofAsn.produit.modele
                        ? ofAsn.produit.modele.code.concat(' ' + ofAsn.produit.modele.description)
                        : ''}
                </Typography>
                <Typography noWrap variant="subtitle1">
                    {!!ofAsn.produit.matiere
                        ? ofAsn.produit.matiere.code.concat(' ' + ofAsn.produit.matiere.description)
                        : ''}
                </Typography>
                <Typography noWrap variant="subtitle1">
                    {!!ofAsn.produit.couleur
                        ? ofAsn.produit.couleur.code.concat(' ' + ofAsn.produit.couleur.description)
                        : ''}
                </Typography>
            </div>
        </div>
    )
}

let styles = theme =>
    createStyles({
        sketch: {
            width: '100px',
            height: '100px',
            objectFit: 'contain',
        },
        produitCard: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            margin: '0.1% 0.5%',
        },
        chipAndSketch: {
            ...defaultStyles.flexColumn,
            alignItems: 'center',
            marginRight: '5%',
        },
    })

export default withStyles(styles, muiOptions)(ProduitInfos)
