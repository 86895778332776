import React, { Fragment, Component } from 'react'
import { t } from 'i18next'
import { observable, observe, makeAutoObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { withStyles, createStyles, List, ListItem, Paper, Grid, Fade, CircularProgress } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import Ligne from './_ligne'
import Calendrier from './_calendrier'
import filtersState from '../../../infrastructure/toolbar/filters/filterGlobalState'
import { state as componentFiltersState } from '../../../infrastructure/toolbar/filters/components/filters'
import { replaceAdditionalFilters } from '../../../infrastructure/toolbar/additionalFilters'
import { state as toolsState, replaceAdditionalTools } from '../../../infrastructure/toolbar/additionalTools'
import filterHelper from '../../../infrastructure/toolbar/filters/filterHelper'
import Pager, { PagerState, CreatePagerState } from '../../../common/pagination/pager'
import { FlatOfReferenceProduit, ReferenceProduit, OrdreDeFabrication, FiltersPost } from '../../models'
import { ExcelButton } from '../../../infrastructure/excel/excelPlanningCreator'
import { state as notificationsState } from '../../../infrastructure/notifications'
import Snackbar from '../../../infrastructure/snackbar'

class PlanningProduitState {
    @observable pagerState: PagerState<FlatOfReferenceProduit>

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new PlanningProduitState()

@observer
class PlanningProduit extends Component<MuiProps, {}> {
    shouldPagersBeDisplayed: boolean = false
    numberOfItemsByPage: number = 10

    ordreDeFabricationToDisplayObserver = observe(filtersState, 'ordreDeFabricationsToDisplay', change => {
        if (filtersState.ordreDeFabricationsToDisplay.length != (change.oldValue as OrdreDeFabrication[]).length)
            state.pagerState.currentPage = 0
        this.initPagerState(filtersState.ordreDeFabricationsToDisplay)
        replaceAdditionalFilters()
        toolsState.replaceAdditionalTools(this.generateAdditionalTools())
    })

    UNSAFE_componentWillMount() {
        this.initPagerState([])
    }

    async componentDidMount() {
        runInAction(() => {
            filtersState.isExcel = false
            filtersState.isExcelAsn = false
        })

        this.initPagerState(filtersState.ordreDeFabricationsToDisplay)
        let currentPageCount = Math.ceil(filtersState.ordreDeFabricationsToDisplay.length / this.numberOfItemsByPage)
        if (currentPageCount != state.pagerState.pageCount) state.pagerState.currentPage = 0
        let body: FiltersPost = {
            ColorCode: [],
            ManufacturerCode: [],
            Number: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            Status: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
        }
        // filterHelper.loadWantedWo(body)
    }

    generateAdditionalTools = (): JSX.Element => (
        <div>
            {ExcelButton(
                state.pagerState.items.map(x => jQuery.extend({}, x)),
                true,
                t('planning.excel.sheetName'),
                t('planning.excel.fileName'),
            )}
        </div>
    )

    componentWillUnmount() {
        this.ordreDeFabricationToDisplayObserver()
    }

    initPagerState(ordreDeFabricationsToDisplay: OrdreDeFabrication[]) {
        let oldPageNumber = !!state.pagerState ? state.pagerState.currentPage : 0

        let ofReferenceProduits: {
            referenceProduit: ReferenceProduit
            of: OrdreDeFabrication
        }[] = ordreDeFabricationsToDisplay
            .map(x => ({ of: x, referenceProduits: x.referenceProduits }))
            .reduce(
                (
                    acc: FlatOfReferenceProduit[],
                    current: { of: OrdreDeFabrication; referenceProduits: ReferenceProduit[] },
                ) => acc.concat(current.referenceProduits.map(x => ({ of: current.of, referenceProduit: x }))),
                [],
            )

        this.shouldPagersBeDisplayed = ofReferenceProduits.length > this.numberOfItemsByPage

        runInAction(() => {
            state.pagerState = CreatePagerState<FlatOfReferenceProduit>(
                ofReferenceProduits,
                oldPageNumber,
                this.numberOfItemsByPage,
            )
        })

        replaceAdditionalFilters()
        toolsState.replaceAdditionalTools(this.generateAdditionalTools())
    }

    render() {
        let classes = this.props.classes
        return (
            <Fragment>
                <Grid container spacing={3} className={classes.fixedPager}>
                    <Grid item xs={12} md={4}>
                        {this.shouldPagersBeDisplayed && (
                            <div className={classes.topPlanningPager}>
                                <Pager
                                    numberOfElementPerPage={this.numberOfItemsByPage}
                                    pagerState={state.pagerState}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8} style={{ padding: 0 }}>
                        <Calendrier />
                    </Grid>
                </Grid>
                <Fade in={componentFiltersState.loadWoIsRunning} unmountOnExit>
                    <CircularProgress className={classes.spinner} size={20} />
                </Fade>
                <List disablePadding={true}>
                    {state.pagerState &&
                        state.pagerState.itemsDisplayed.map((x, index) => (
                            <ListItem
                                key={index + ' planningOrdreFabricationView'}
                                className={classes.listItem}
                                disableGutters={true}>
                                <Paper className={classes.backgroundItemPaper}>
                                    <Ligne of={x.of} referenceProduit={x.referenceProduit} />
                                </Paper>
                            </ListItem>
                        ))}
                </List>
                {this.shouldPagersBeDisplayed && (
                    <Pager numberOfElementPerPage={this.numberOfItemsByPage} pagerState={state.pagerState} />
                )}
                {notificationsState
                    .getNotifications()
                    .map(notif => <Snackbar message={notif.message} variant={notif.variant} key={notif.key} />)}
            </Fragment>
        )
    }
}

let styles = theme =>
    createStyles({
        fixedPager: {
            position: 'sticky',
            top: 130,
            zIndex: 1,
            backgroundColor: '#fafafa',
            width: '100%',
            margin: '-25px 0 0',
        },
        backgroundItemPaper: {
            display: 'block',
            width: '100%',
            paddingRight: '65px',
        },
        listItem: {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
        topPlanningPager: {
            position: 'absolute',
        },
        spinner: {
            ...defaultStyles.spinner,
        },
    })

export default withStyles(styles, muiOptions)(PlanningProduit)
