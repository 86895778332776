import React, { Component } from 'react'
import jQuery from 'jquery'
import { observable, computed, action, flow, observe, makeAutoObservable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { IconButton, NoSsr, Paper, Select, Input, Button } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import { MuiProps, muiOptions, defaultStyles, theme } from '../../../materialUiThemeProvider'
import { FiltersPost, OrdreDeFabrication, OrdreDeFabricationFiltered } from '../../../../ordreDeFabrication/models'
import { FilterType, OrdreDeFabricationFilters } from '../models'
import filtersState from '../filterGlobalState'
import filterHelper from '../filterHelper'
import FilterSelector from './filterSelector'
import Accumulator from './accumulator'
import AsnAdditionalFilters from '../../asnFilters/components/asnFilters'
import FilterInput from './filterInput'
import { state as I18nextState } from '../../../i18nextHelper'

import api from '../../../api'
import deepEqual from 'deep-equal'
import { toolbarState } from '../../toolbar'
import { t } from 'i18next'
import { timingSafeEqual } from 'crypto'
import { Theme } from '@material-ui/core/styles'

interface FilterProps extends WithStyles<typeof styles> {
    handleOpenCloseFilters: () => void
    classes: any
    theme?: Theme
}

enum WoRequest {
    OnlyClosed,
    OnlyOpened,
    All,
}
enum WoReceivedStatus {
    None,
    OnlyOpened,
    All,
}

class FiltersState {
    @observable numberOfAppliedFilterTypes: number = 0
    @observable appliedFilters: { [type: string]: { [keys: string]: number } } = {}
    @observable hideClosedWo: boolean = true
    @observable loadWoIsRunning: boolean = false
    @observable woReceived: WoReceivedStatus = WoReceivedStatus.None
    @observable lastUpdateWo: number = 0
    @observable filterChanged: boolean = false
    @observable isFiltered: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

const state = new FiltersState()

@observer
class Filters extends Component<FilterProps, {}> {
    constructor(props) {
        super(props)
        state.filterChanged = false
        this.state = {
            filterChanged: false,
        }
    }

    reloadFilterWhenTheLanguageisChanged = observe(I18nextState, 'language', _ => {
        this.forceUpdate()
    })

    reloadFiltersWhenTheWOAreToggled = observe(state, 'hideClosedWo', async _ => {
        let result = filterHelper.filtersFlat(state.appliedFilters)

        let body: FiltersPost = {
            ColorCode: [],
            ManufacturerCode: [],
            ShopCode: [],
            Number: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            Status: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
        }
        result.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.Status.push(x.key)
                    break
                case '#WO':
                    body.Number.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
        await filterHelper.loadWantedWo(body)
        this.applyFilters()
    })

    async componentDidMount() {
        const data = window.localStorage.getItem('MY_FAVORITES')

        filterHelper.resetFilters()
        let parse = data ? JSON.parse(data) : ''
        runInAction(() => (state.appliedFilters = parse))
        toolbarState.body = parse
        let result = filterHelper.filtersFlat(state.appliedFilters)
        if (state.filterChanged || data) {
            state.filterChanged = false
            let body: FiltersPost = {
                ColorCode: [],
                ManufacturerCode: [],
                ShopCode: [],
                Number: [],
                SeasonCode: [],
                TypeWO: [],
                Group: [],
                Status: [],
                SubActivityCode: [],
                TypeCode: [],
                Gender: [],
                ModelCode: [],
                MaterialCode: [],
            }
            result.forEach(x => {
                switch (x.type) {
                    case 'color':
                        body.ColorCode.push(x.key)
                        break
                    case 'manufacturer':
                        body.ManufacturerCode.push(x.key)
                        break
                    case 'shop':
                        body.ShopCode.push(x.key)
                        break
                    case 'season':
                        body.SeasonCode.push(x.key)
                        break
                    case 'typeWO':
                        body.TypeWO.push(x.key)
                        break
                    case 'group':
                        body.Group.push(x.key)
                        break
                    case 'status':
                        body.Status.push(x.key)
                        break
                    case '#WO':
                        body.Number.push(x.key)
                        break
                    case 'subActivity':
                        body.SubActivityCode.push(x.key)
                        break
                    case 'type':
                        body.TypeCode.push(x.key)
                        break
                    case 'gender':
                        body.Gender.push(x.key)
                        break
                    case 'model':
                        body.ModelCode.push(x.key)
                        break
                    case 'material':
                        body.MaterialCode.push(x.key)
                        break
                }
            })
            await filterHelper.loadWantedWo(body)
        } else {
            let body: FiltersPost = {
                ColorCode: [],
                ManufacturerCode: [],
                ShopCode: [],
                Number: [],
                SeasonCode: [],
                TypeWO: [],
                Group: [],
                Status: [],
                SubActivityCode: [],
                TypeCode: [],
                Gender: [],
                ModelCode: [],
                MaterialCode: [],
            }
            filterHelper.loadWantedWo(body)
        }
        this.applyFilters()
    }

    resetFilters() {
        filterHelper.resetFilters()
        state.appliedFilters = {}
        this.applyFilters()
    }

    async applyFilters() {
        toolbarState.body = state.appliedFilters

        let result = filterHelper.filtersFlat(state.appliedFilters)
        let body: FiltersPost = {
            ColorCode: [],
            ManufacturerCode: [],
            ShopCode: [],
            Number: [],
            SeasonCode: [],
            TypeWO: [],
            Group: [],
            Status: [],
            SubActivityCode: [],
            TypeCode: [],
            Gender: [],
            ModelCode: [],
            MaterialCode: [],
        }
        result.forEach(x => {
            switch (x.type) {
                case 'color':
                    body.ColorCode.push(x.key)
                    break
                case 'manufacturer':
                    body.ManufacturerCode.push(x.key)
                    break
                case 'shop':
                    body.ShopCode.push(x.key)
                    break
                case 'season':
                    body.SeasonCode.push(x.key)
                    break
                case 'typeWO':
                    body.TypeWO.push(x.key)
                    break
                case 'group':
                    body.Group.push(x.key)
                    break
                case 'status':
                    body.Status.push(x.key)
                    break
                case '#WO':
                    body.Number.push(x.key)
                    break
                case 'subActivity':
                    body.SubActivityCode.push(x.key)
                    break
                case 'type':
                    body.TypeCode.push(x.key)
                    break
                case 'gender':
                    body.Gender.push(x.key)
                    break
                case 'model':
                    body.ModelCode.push(x.key)
                    break
                case 'material':
                    body.MaterialCode.push(x.key)
                    break
            }
        })
        await filterHelper.loadWantedWo(body)
        const ofs = filterHelper.filterOpenedWo(filtersState.ordreDeFabricationsTotal.map(x => jQuery.extend({}, x)))
        filterHelper.resetFilters()
        filterHelper.updateFilters(filtersState.filters)
        filtersState.ordreDeFabricationsToDisplay = ofs
        this.forceUpdate()
    }
    applyMoreFilters() {
        toolbarState.isExpended = false
    }

    handleChange = async (type: FilterType, filterKey: string) => {
        const filters = jQuery.extend({}, state.appliedFilters)
        const createNewFilterType = () => {
            filters[type] = {}
            filters[type][filterKey] = state.numberOfAppliedFilterTypes
            state.numberOfAppliedFilterTypes++
        }
        const addKeyToFilter = () => {
            filters[type][filterKey] = state.numberOfAppliedFilterTypes
        }
        const deleteKeyFromFilter = () => {
            delete filters[type][filterKey]
            if (Object.keys(filters[type]).length == 0) {
                delete filters[type]
                state.numberOfAppliedFilterTypes--
            }
        }

        const filterExistOnIndex = !!filters[type]

        if (!filterExistOnIndex) createNewFilterType()
        else {
            const keyExistOnFilter = filters[type][filterKey] != undefined

            if (!keyExistOnFilter) addKeyToFilter()
            else deleteKeyFromFilter()
        }

        state.filterChanged = true
        state.appliedFilters = filters

        this.setState({ filterChanged: true })
        this.applyFilters()
    }

    handleReset = () => {
        this.resetFilters()
    }

    render(): React.ReactNode {
        const classes = this.props.classes
        const filters = state.appliedFilters
        let props = this.props as MuiProps & FilterProps
        let handleOpenCloseFilters = props.handleOpenCloseFilters

        return (
            <div className={classes.filterZone}>
                <Paper className={classes.globalOrdreDeFabricationsFiltersZone}>
                    <div>
                        <Accumulator
                            handleDeleteFilter={this.handleChange.bind(this)}
                            filtersAccumulated={filterHelper.filtersFlat(state.appliedFilters)}
                            getLabelForKey={filterHelper.getLabelValue}
                        />
                    </div>
                    <div className={classes.filtersContainer}>
                        <IconButton
                            color="primary"
                            className={classes.button}
                            aria-label="Reset"
                            onClick={() => this.handleReset()}
                            style={{ alignSelf: 'center', marginLeft: '10px' }}>
                            <Icons.SettingsBackupRestore />
                        </IconButton>
                        <div className={classes.filterColumnsContainer}>
                            <div className={classes.filtersColumn}>
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.fabricant}
                                    typeValues={filtersState.filtersData.fabricant}
                                    selectedItems={
                                        !!filters[FilterType.fabricant] && Object.keys(filters[FilterType.fabricant])
                                    }
                                />
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.magasin}
                                    typeValues={filtersState.filtersData.magasin}
                                    selectedItems={
                                        !!filters[FilterType.magasin] && Object.keys(filters[FilterType.magasin])
                                    }
                                />
                            </div>
                            <div className={classes.filtersColumn}>
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.sousActivite}
                                    typeValues={filtersState.filtersData['sousActivite']}
                                    selectedItems={
                                        !!filters[FilterType.sousActivite] &&
                                        Object.keys(filters[FilterType.sousActivite])
                                    }
                                />
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.saison}
                                    typeValues={filtersState.filtersData.saison}
                                    selectedItems={
                                        !!filters[FilterType.saison] && Object.keys(filters[FilterType.saison])
                                    }
                                />
                            </div>
                            <div className={classes.filtersColumn}>
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.type}
                                    typeValues={filtersState.filtersData.type}
                                    selectedItems={!!filters[FilterType.type] && Object.keys(filters[FilterType.type])}
                                />
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.typeOrdreDeFabrication}
                                    typeValues={filtersState.filtersData.typeWO}
                                    selectedItems={
                                        !!filters[FilterType.typeOrdreDeFabrication] &&
                                        Object.keys(filters[FilterType.typeOrdreDeFabrication])
                                    }
                                />
                            </div>
                            <div className={classes.filtersColumn}>
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.genre}
                                    typeValues={filtersState.filtersData.genre}
                                    selectedItems={
                                        !!filters[FilterType.genre] && Object.keys(filters[FilterType.genre])
                                    }
                                />
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.groupage}
                                    typeValues={filtersState.filtersData.groupage}
                                    selectedItems={
                                        !!filters[FilterType.groupage] && Object.keys(filters[FilterType.groupage])
                                    }
                                />
                            </div>
                            <div className={classes.filtersColumn}>
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.modele}
                                    typeValues={filtersState.filtersData.modele}
                                    selectedItems={
                                        !!filters[FilterType.modele] && Object.keys(filters[FilterType.modele])
                                    }
                                />
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.statut}
                                    typeValues={filtersState.filtersData.statut}
                                    selectedItems={
                                        !!filters[FilterType.statut] && Object.keys(filters[FilterType.statut])
                                    }
                                />
                            </div>
                            <div className={classes.filtersColumn}>
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.matiere}
                                    typeValues={filtersState.filtersData.material}
                                    selectedItems={
                                        !!filters[FilterType.matiere] && Object.keys(filters[FilterType.matiere])
                                    }
                                />
                                <FilterSelector
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.couleur}
                                    typeValues={filtersState.filtersData.couleur}
                                    selectedItems={
                                        !!filters[FilterType.couleur] && Object.keys(filters[FilterType.couleur])
                                    }
                                />
                            </div>
                            <div className={classes.filtersColumn}>
                                <FilterInput
                                    handleChange={this.handleChange.bind(this)}
                                    type={FilterType.numeroOrdreDeFabrication}
                                    parse={false}
                                />
                            </div>
                        </div>
                        {/* <IconButton
                            color="primary"
                            className={classes.button}
                            aria-label="Reset"
                            onClick={() => this.handleReset()}
                            style={{ alignSelf: 'center', marginLeft: '10px' }}>
                            <Icons.SettingsBackupRestore />
                        </IconButton> */}
                        <Button variant="contained" className={classes.button} onClick={handleOpenCloseFilters}>
                            {t('filters.apply')}
                        </Button>
                    </div>
                </Paper>
                <AsnAdditionalFilters />
            </div>
        )
    }
}

const styles = theme =>
    createStyles({
        filterZone: {
            ...defaultStyles.flexColumn,
            width: '100%',
        },
        globalOrdreDeFabricationsFiltersZone: {
            marginBottom: '1%',
        },
        filtersContainer: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        filterColumnsContainer: {
            ...defaultStyles.flexRow,
            flexGrow: 0.98,
            paddingBottom: '10px',
            paddingLeft: '1%',
        },
        filtersColumn: {
            ...defaultStyles.flexColumn,
            justifyContent: 'center',
            alignItems: 'start',
            width: '100%',
            paddingRight: '1%',
        },
    })

export default withStyles(styles, muiOptions)(Filters)
export { state, WoRequest, WoReceivedStatus }
