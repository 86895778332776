import React from 'react'
import { t } from 'i18next'
import { Button, Tooltip } from '@material-ui/core'
import {
    PlanificationProductionToExport,
    FlatOfReferenceProduit,
    StatutSemaine,
    StatutSkus,
    QuantiteSemaine,
    QuantiteStatut,
} from '../../ordreDeFabrication/models'
import api from '../api'
import { getListeTraductions, ExportExcel } from './_excelHelper'

let generateDataForExcelExport = (
    dataToMap: FlatOfReferenceProduit[],
    shouldDisplayQuantiteSemaine: boolean,
): PlanificationProductionToExport[] => {
    let dataToSend: PlanificationProductionToExport[] = []

    let buildPlanificationProductionElement = (
        x: FlatOfReferenceProduit,
        lineType: string,
        calendrier: { [key: string]: number },
        statuts: { [key: string]: number },
    ): PlanificationProductionToExport => {
        return {
            Manufacturer: x.of.fabricant ? x.of.fabricant.label : '',
            SaisonCode: x.of.saison ? x.of.saison.code : '',
            SaisonDescription: x.of.saison ? x.of.saison.description : '',
            WOType: t('typeOf.'.concat(x.of.typeOrdreDeFabrication.toLowerCase())),
            Groupage: x.of.groupage || '',
            WorkOrder: x.of.numero,
            Status: t('statutOf.'.concat(x.referenceProduit.statut.toLowerCase())),
            Activity: x.referenceProduit.produit.activite ? x.referenceProduit.produit.activite.description : '',
            SubActivity: x.referenceProduit.produit.sousActivite
                ? x.referenceProduit.produit.sousActivite.description
                : '',
            Type: x.referenceProduit.produit.type ? x.referenceProduit.produit.type.description : '',
            Gender: x.referenceProduit.produit.gender || '',
            Product: x.referenceProduit.produitCode,
            ModelCode: x.referenceProduit.produit.modele ? x.referenceProduit.produit.modele.code : '',
            ModelDescription: x.referenceProduit.produit.modele ? x.referenceProduit.produit.modele.description : '',
            MaterialCode: x.referenceProduit.produit.matiere ? x.referenceProduit.produit.matiere.code : '',
            MaterialDescription: x.referenceProduit.produit.matiere
                ? x.referenceProduit.produit.matiere.description
                : '',
            ColorCode: x.referenceProduit.produit.couleur ? x.referenceProduit.produit.couleur.code : '',
            ColorDescription: x.referenceProduit.produit.couleur ? x.referenceProduit.produit.couleur.description : '',
            QuantiteStatuts: getQuantiteStatut(x.referenceProduit.quantiteStatuts, statuts),
            LineType: t('planning.type.'.concat(lineType.toLowerFirstChar())),
            QuantiteSemaines: shouldDisplayQuantiteSemaine
                ? getQuantiteSemaine(x.referenceProduit.quantiteSemaines, lineType, calendrier)
                : {},
        }
    }

    const quantiteSemaineToString = (quantiteSemaine: QuantiteSemaine): string => {
        const semaine = quantiteSemaine.semaine
        return semaine.annee.toString() + '-' + (semaine.numero < 10 ? '0' : '') + semaine.numero.toString()
    }

    const buildCalendrierElements = (dataToMap: FlatOfReferenceProduit[]): { [key: string]: number } => {
        let semaines: string[] = []
        dataToMap.forEach(flatOfReferenceProduit => {
            flatOfReferenceProduit.referenceProduit.quantiteSemaines.forEach(quantiteSemaine => {
                semaines.push(quantiteSemaineToString(quantiteSemaine))
            })
        })

        let calendar = {}
        semaines.sort().forEach(semaine => {
            calendar[semaine] = 0
        })
        return calendar
    }

    let buildStatutsElements = (): { [key: string]: number } => {
        let statuts: { [key: string]: number } = {}
        for (let element in StatutSkus) {
            let prop = t('status.'.concat(element.toLowerFirstChar()))
            statuts[prop] = 0
        }
        return statuts
    }

    let getQuantiteStatut = (
        quantiteStatuts: QuantiteStatut[],
        listeStatuts: { [key: string]: number },
    ): { [key: string]: number } => {
        let result: { [key: string]: number } = {}

        Object.assign(result, listeStatuts)
        for (let i = 0; i < quantiteStatuts.length; i++) {
            let prop = t('status.'.concat(quantiteStatuts[i].statutSku.toLowerFirstChar()))
            let quantite = quantiteStatuts[i].quantite
            result[prop] = quantite
        }

        return result
    }

    let getQuantiteSemaine = (
        quantiteSemaines: QuantiteSemaine[],
        lineType: string,
        defaultCalendrier: { [key: string]: number },
    ): { [key: string]: number } => {
        let result: { [key: string]: number } = {}
        Object.assign(result, defaultCalendrier)

        const quantitesSemaine = quantiteSemaines.filter(x => x.statutSemaine == lineType)
        quantitesSemaine.forEach(quantiteSemaine => {
            result[quantiteSemaineToString(quantiteSemaine)] = quantiteSemaine.quantite ? quantiteSemaine.quantite : 0
        })

        return result
    }

    let calendrier: { [key: string]: number } = buildCalendrierElements(dataToMap)
    let statuts: { [key: string]: number } = buildStatutsElements()
    dataToMap.forEach(element => {
        let newData: PlanificationProductionToExport[] = []
        for (let lineType in StatutSemaine) {
            newData.push(buildPlanificationProductionElement(element, lineType, calendrier, statuts))
        }
        if (dataToSend.length == 0) dataToSend = newData
        else newData.map(x => dataToSend.push(x))
    })

    return dataToSend
}

let exportDataToExcel = async (
    dataToMap: FlatOfReferenceProduit[],
    shouldDisplayQuantiteSemaine: boolean,
    sheetName: string,
    fileName: string,
) => {
    let data = generateDataForExcelExport(dataToMap, shouldDisplayQuantiteSemaine)
    let traductions = data && data.length > 0 ? getListeTraductions(data[0]) : {}
    let excelUrl = await api.post<ExportExcel<PlanificationProductionToExport>>(
        `excelFile/generatePlanning/${fileName}/${sheetName}`,
        {
            DataToIntegrate: data,
            Traductions: traductions,
        },
    )
    window.open(`${api.apiUrl}${excelUrl.url}`)
}

let ExcelButton: (
    dataToMap: FlatOfReferenceProduit[],
    shouldDisplayQuantiteSemaine: boolean,
    sheetName: string,
    fileName: string,
) => JSX.Element = (
    dataToMap: FlatOfReferenceProduit[],
    shouldDisplayQuantiteSemaine: boolean,
    sheetName: string,
    fileName: string,
    ) => (
            <Tooltip title={t('common.exportExcel')}>
                <Button
                    color={'default'}
                    onClick={() => exportDataToExcel(dataToMap, shouldDisplayQuantiteSemaine, sheetName, fileName)}
                    style={{ padding: '0px' }}>
                    <img src="/static/img/excel_icon.svg" />
                </Button>
            </Tooltip>
        )

export { ExcelButton }
