import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { muiOptions, defaultStyles } from '../materialUiThemeProvider'
import Header from './header'
import Menu from './menu'
import api from '../api'
import { NotificationsState, state as notificationsState, NotificationsStpre } from '../../infrastructure/notifications'
import Toolbar from '../toolbar/toolbar'
import Snackbar from '../snackbar'

const Shell = props => {
    const { classes } = props
    const [version, setVersion] = useState('')
    const [isMobileOpen, setIsMobileOpen] = useState(false)

    const checkBackend = useCallback(async () => {
        const ping = (await api.ping()).version
        setVersion(ping)
    }, [])

    useEffect(
        () => {
            checkBackend()
        },
        [checkBackend],
    )

    const handleDrawerToggle = () => {
        setIsMobileOpen(prev => !prev)
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <Header handleDrawerToggle={handleDrawerToggle} />
                <Menu handleDrawerToggle={handleDrawerToggle} mobileOpen={isMobileOpen} version={'v: ' + version} />
                <main className={classes.middleView}>
                    <Toolbar />
                    <div className={classes.content}>{props.children}</div>
                </main>
            </div>
            {/* <NotificationsStpre.Provider value={new NotificationsState()}>
                <Snackbar />
            </NotificationsStpre.Provider> */}
        </Fragment>
    )
}

let styles = theme =>
    createStyles({
        root: {
            ...defaultStyles.flexRow,
            flexGrow: 1,
            zIndex: 1,
            position: 'relative',
        },
        middleView: {
            ...defaultStyles.flexColumn,
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
            [theme.breakpoints.up('lg')]: {
                marginLeft: 240,
            },
        },
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            paddingTop: '90px',
        },
    })

export default withStyles(styles, muiOptions)(Shell)
