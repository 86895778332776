import React, { Component, Fragment, useEffect } from 'react'
import { navigateBack } from '../infrastructure/navigation'
import { useNavigate } from 'react-router-dom'

const changeLanguage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(-1)
    }, [])

    return <Fragment />
}

export default changeLanguage
