import React, { Component, Fragment } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import moment from 'moment'
import { Paper, Typography, TextField, Button, CircularProgress } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { createStyles, withStyles } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import filterHelper from '../../infrastructure/toolbar/filters/filterHelper'
import { DetailsAdvancedShippingNotice, AjouterNote } from '../models'
import api from '../../infrastructure/api'
import { showNotification } from '../../infrastructure/notifications'
import LigneProduitQuantitesAsn from './_ligneProduitQuantitesAsn'
import Stepper from './stepper'
import { Steps } from '../models'
import AsnStatutChip from './asnStatutChip'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type AsnDetailsProps = {
    match: { params: { numero: string } }
}

class AsnDetailsState {
    @observable asn: DetailsAdvancedShippingNotice | null = null
    @observable previousNote: string = ''
    @observable isLoading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

let state = new AsnDetailsState()

@observer
class AsnDetails extends Component<MuiProps & AsnDetailsProps> {
    async componentDidMount() {
        filterHelper.setFilterVisibility(false)
        state.asn = await this.loadDetailsASN()
    }

    componentDidUpdate() {
        filterHelper.setFilterVisibility(false)
    }

    componentWillUnmount() {
        filterHelper.setFilterVisibility(true)
        state.asn = null
    }

    loadDetailsASN = async (): Promise<DetailsAdvancedShippingNotice> => {
        return await api.get<DetailsAdvancedShippingNotice>(`asn/${this.props.match.params.numero}/details`)
    }
    exportExcel = async (): Promise<void> => {
        state.isLoading = true
        let result: any
        if (state.asn) {
            result = await api.get<any>(`excelFile/ASN/${state.asn.numero}`)

            var sampleArr = this.base64ToArrayBuffer(result.b64)
            let blob = new Blob([sampleArr])
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `Asn ${state.asn.numero}.xlsx`
            link.click()
        }
        state.isLoading = false
    }

    base64ToArrayBuffer = (base64): any => {
        var binaryString = window.atob(base64)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i)
            bytes[i] = ascii
        }
        return bytes
    }

    savePreviousNote = (targetValue: any) => {
        state.previousNote = targetValue.value
    }

    changeNote = (value: string) => {
        if (state.asn) {
            state.asn.note = value
        }
    }

    sendNote = async (value: string) => {
        let valueChanged = value != state.previousNote
        try {
            if (valueChanged && state.asn) {
                await api.post<AjouterNote>(`asn/ajouterNote`, {
                    AdvancedShippingNoticeId: state.asn.id,
                    Note: value,
                })
            }
        } catch (e) {
            showNotification(e.message, 'error')
        } finally {
            await this.loadDetailsASN()
        }
    }

    printDeliveryNote = async (asnNumero: number | null) => {
        if (asnNumero) {
            if (state.asn) this.sendNote(state.asn.note)
            let pdfUrl = await api.post(`asnBonDeLivraison/generate/${asnNumero}`, {})
            window.open(`${api.apiUrl}${pdfUrl.url}`)
        }
    }

    render() {
        let { classes } = this.props

        return !state.asn ? (
            <CircularProgress className={classes.spinner} size={20} />
        ) : (
            <Fragment>
                <div className={classes.stepperHeader}>
                    <div className={classes.stepper}>
                        <Stepper activeStep={Steps.Created} />
                    </div>
                    <div className={classes.magasinRootContainer}>
                        <Icons.Store className={classes.magasinIcon} />
                        <div>
                            <Typography className={classes.magasinReception} variant="subtitle1">
                                {t('asn.magasinReception')}
                            </Typography>
                            <Typography className={classes.magasinReceptionLabel} variant="subtitle1">
                                {state.asn.magasinReception ? state.asn.magasinReception.label : state.asn.magasinCode}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.stepperHeaderButtons}>
                        <Button variant="contained" className={classes.button} onClick={() => history.back()}>
                            <Icons.ArrowBack />
                            {t('common.back')}
                        </Button>
                        {/* <Button
                                    variant={undefined}
                                    color={'default'}
                                    disabled={state.isLoading}
                                    onClick={() => this.exportExcel()}
                                    style={{ padding: '0px' }}>
                                    {state && state.isLoading ? <CircularProgress />
                                        : <img src="./../../../static/img/excel_icon.svg" />}
                                </Button> */}
                    </div>
                </div>
                <Paper className={classes.header}>
                    <div className={classes.headerItem}>
                        <div className={classes.asnInfos}>
                            <div className={classes.asnInfo}>
                                <Typography variant={'h6'}>{t('asn.asnDetails.asnNumber')}</Typography>
                                <AsnStatutChip
                                    className={classes.chip}
                                    label={state.asn.numero.pad(8)}
                                    statut={state.asn.statut}
                                    disableTooltip={false}
                                />
                            </div>
                            <div className={classes.asnInfo}>
                                <Typography variant={'h6'}>{t('asn.asnDetails.shipDate')}</Typography>
                                <Typography variant={'subtitle1'} className={classes.typo}>
                                    {moment(state.asn.dateExpedition).format(t('date.format.date'))}
                                </Typography>
                            </div>
                            <div className={classes.asnInfo}>
                                <Typography variant={'h6'}>{t('asn.asnDetails.totalQuantity')}</Typography>
                                <Typography variant={'subtitle1'} className={classes.typo}>
                                    {state.asn.totalAExpedier}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div style={{ flexGrow: 1 }} className={classes.headerItem}>
                        <TextField
                            value={state.asn && state.asn.note ? state.asn.note : undefined}
                            onFocus={e => this.savePreviousNote(e.target)}
                            onChange={e => this.changeNote(e.target.value)}
                            onBlur={e => this.sendNote(e.target.value)}
                            label={t('asn.asnDetails.printedNotes')}
                            multiline
                            rows="3"
                            className={classes.textField}
                            margin="normal"
                            inputProps={{
                                maxLength: 2000,
                            }}
                        />
                    </div>
                    <div className={classes.headerItem}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => this.printDeliveryNote(state.asn ? state.asn.numero : null)}>
                            {t('asn.asnDetails.printDeliveryNote')}
                        </Button>
                    </div>
                </Paper>
                {state.asn.ofAsns.map(x => (
                    <LigneProduitQuantitesAsn
                        key={x.ordreDeFabricationNumber.concat(x.produit.code)}
                        ofAsn={x}
                        editable={false}
                        detailedView={true}
                        magasinCode={!!state.asn ? state.asn.magasinCode : ''}
                    />
                ))}
            </Fragment>
        )
    }
}

let styles = theme =>
    createStyles({
        stepperHeader: {
            ...(defaultStyles.flexRow as CSSProperties),
            marginTop: '-50px',
        },
        stepper: {
            ...(defaultStyles.flexRow as CSSProperties),
            width: '80%',
        },
        header: {
            ...(defaultStyles.flexRow as CSSProperties),
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 5,
        },
        headerItem: {
            ...(defaultStyles.flexRow as CSSProperties),
        },
        asnInfos: {
            ...(defaultStyles.flexRow as CSSProperties),
            justifyContent: 'center',
            marginTop: 10,
        },
        asnInfo: {
            ...(defaultStyles.flexColumn as CSSProperties),
            alignItems: 'center',
            margin: '5px 15px',
        },
        typo: {
            marginTop: 5,
        },
        boldTypo: {
            fontWeight: 'bold',
            marginTop: 5,
        },
        textField: {
            ...(defaultStyles.textField as CSSProperties),
            flexGrow: 1,
        },
        button: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        magasinRootContainer: {
            ...(defaultStyles.flexColumn as CSSProperties),
            alignItems: 'center',
            paddingTop: '1%',
        },
        stepperHeaderButtons: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignSelf: 'center',
            padding: '0 1rem',
        },
        magasinIcon: {
            width: '2em',
            height: '2em',
            color: defaultColors.primary.dark.color,
        },
        magasinReceptionLabel: {
            textAlign: 'center',
            fontWeight: 'bold',
        },
        magasinReception: {
            textAlign: 'center',
            fontSize: '0.8rem',
            color: defaultColors.grey.light.text,
        },
        chip: {
            cursor: 'pointer',
            width: '100%',
        },
        spinner: {
            ...(defaultStyles.spinner as CSSProperties),
        },
    })

export default withStyles(styles, muiOptions)(AsnDetails)
