import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles } from '@material-ui/core'
import { grey, green, blue, red, amber } from '@material-ui/core/colors/'
import { Theme, createTheme } from '@material-ui/core/styles'

export type MuiProps = {
    classes: any
    theme?: Theme
}

function createMaterialUiTheme(options: any) {
    return createTheme({
        ...options,
    })
}

export let theme = createMaterialUiTheme({
    palette: {
        primary: {
            light: '#6B6B73',
            main: '#35363A',
            dark: '#27272B',
            contrastText: '#FFF',
        },
        secondary: red,
        error: red,
    },
    breakpoints: {
        values: {
            lg: 1440,
        },
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                color: 'inherit',
            },
        },
        MuiTypography: {
            subtitle1: {
                color: 'inherit',
            },
        },
        MuiFormControlLabel: {
            label: {
                whiteSpace: 'nowrap',
            },
        },
        MuiTableCell: {
            head: {
                color: 'inherit',
            },
        },
        MuiChip: {
            root: {
                cursor: 'inherit',
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: '0 .5rem',
            },
            content: {
                '& > :last-child': {
                    paddingRight: '16px',
                },
            },
        },
    },
})

export let muiOptions = { withTheme: true }

export const drawerWidth = 240

export const defaultColors = {
    primary: {
        light: {
            color: theme.palette.primary.light,
            text: theme.palette.getContrastText(theme.palette.primary.light),
        },
        main: {
            color: theme.palette.primary.main,
            text: theme.palette.getContrastText(theme.palette.primary.main),
        },
        dark: {
            color: theme.palette.primary.dark,
            text: theme.palette.getContrastText(theme.palette.primary.dark),
        },
    },
    secondary: {
        light: {
            color: theme.palette.secondary.light,
            text: theme.palette.getContrastText(theme.palette.secondary.light),
        },
        main: {
            color: theme.palette.secondary.main,
            text: theme.palette.getContrastText(theme.palette.secondary.main),
        },
        dark: {
            color: theme.palette.secondary.dark,
            text: theme.palette.getContrastText(theme.palette.secondary.dark),
        },
    },
    grey: {
        light: {
            color: grey['100'],
            text: theme.palette.getContrastText(grey['100']),
        },
        main: {
            color: grey['300'],
            text: theme.palette.getContrastText(grey['300']),
        },
        dark: {
            color: grey['500'],
            text: theme.palette.getContrastText(grey['500']),
        },
    },
    blue: {
        light: {
            color: blue['300'],
            text: theme.palette.getContrastText(blue['300']),
        },
        main: {
            color: blue['500'],
            text: theme.palette.getContrastText(blue['500']),
        },
        dark: {
            color: blue['700'],
            text: theme.palette.getContrastText(blue['700']),
        },
    },
    green: {
        light: {
            color: green['300'],
            text: theme.palette.getContrastText(green['300']),
        },
        main: {
            color: green['500'],
            text: theme.palette.getContrastText(green['500']),
        },
        dark: {
            color: green['700'],
            text: theme.palette.getContrastText(green['700']),
        },
    },
    amber: {
        light: {
            color: amber['300'],
            text: theme.palette.getContrastText(amber['300']),
        },
        main: {
            color: amber['500'],
            text: theme.palette.getContrastText(amber['500']),
        },
        dark: {
            color: amber['700'],
            text: theme.palette.getContrastText(amber['700']),
        },
    },
    red: {
        light: {
            color: red['300'],
            text: theme.palette.getContrastText(red['300']),
        },
        main: {
            color: red['500'],
            text: theme.palette.getContrastText(red['500']),
        },
        dark: {
            color: red['700'],
            text: theme.palette.getContrastText(red['700']),
        },
    },
}

export const borderStyle = '1px solid ' + defaultColors.grey.main.color

export const defaultStyles = createStyles({
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        width: '50px',
        textAlign: 'center',
    },
    disabledInput: {
        '&:before': {
            borderBottom: '0px solid black !important',
        },
    },
    inputError: {
        width: '50px',
        textAlign: 'center',
        backgroundColor: 'red',
        color: 'white',
    },
    chipNumeroOf: {
        fontWeight: 'bold',
    },
    effect3D: {
        '&:focus-within': {
            boxShadow: '0px 10px 10px 4px rgba(0, 0, 0, 0.2), 0px 5px 10px 0px ' + defaultColors.primary.main.color,
        },
        '&:focus-within $headerRow': {
            backgroundColor: defaultColors.primary.main.color,
        },
        '&:focus-within $headerCell': {
            color: defaultColors.primary.main.text,
        },
    },
    headerRow: {
        backgroundColor: defaultColors.primary.main.color,
        color: defaultColors.primary.main.text,
    },
    headerCell: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1rem',
        '&:first-child': {
            textAlign: 'left',
        },
        '&:last-child': {
            textAlign: 'right',
        },
    },
    tableRow: {
        height: '25px',
    },
    tableCell: {
        textAlign: 'center',
        padding: '5px 20px',
        '&:first-child': {
            fontWeight: 'bold',
            textAlign: 'left',
        },
        '&:last-child': {
            fontWeight: 'bold',
            textAlign: 'right',
        },
    },
    evenTableRow: {
        backgroundColor: defaultColors.grey.light.color,
        color: defaultColors.grey.light.text,
    },
    week: {
        padding: '5px',
        width: '40px',
        textAlign: 'center',
        margin: '8px',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        border: borderStyle,
    },
    button: {
        margin: theme.spacing(),
    },
    buttonFab: {
        margin: theme.spacing(),
        width: '36px',
        height: '36px',
    },
    buttonNavigation: {
        color: defaultColors.primary.dark.text,
        backgroundColor: defaultColors.primary.main.color,
        '&:hover': {
            backgroundColor: defaultColors.primary.dark.color,
        },
    },
    buttonAction: {
        color: defaultColors.green.dark.text,
        backgroundColor: defaultColors.green.main.color,
        '&:hover': {
            backgroundColor: defaultColors.green.dark.color,
        },
    },
    buttonAnnulation: {
        color: defaultColors.grey.dark.text,
        backgroundColor: defaultColors.grey.main.color,
        '&:hover': {
            backgroundColor: defaultColors.grey.dark.color,
        },
    },
    hidden: {
        display: 'none',
    },
    spinner: {
        display: 'block',
        margin: '1rem auto',
    },
})

export let CelineIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="32" {...props}>
        <path
            d="M136.796456 25.6277776v-8.8027165h9.65357v-4.4970069h-9.65357V5.350254h11.969822V.8517289H131.85401v29.2715374H150v-4.4954887h-13.203544zm-13.58757 5.6204996L123.246684.813773h-4.942447l.077108 19.2678985L96.683869 0l-.03931 30.1217481h4.942447l-.07862-18.5300371 21.7005 19.6565662zm-35.21701-1.1265291L87.9949.813773h-4.940935l-.004535 29.3079751h4.942446zm-28.2969802.0015182H76.876285v-4.4954887H64.6373423V.813773h-4.9424465v29.3094933zm-25.5604161 0H52.281982v-4.4954887H39.0769262v-8.8027165h9.6520582v-4.4970069h-9.6520582V5.350254h11.9698222V.8517289H34.1344797v29.2715374zM5.2508769 15.314419c0-5.584062 3.8614281-10.6625523 10.4246462-10.6625523 3.0117325 0 5.9841552 1.0855368 8.0312866 3.1017506l2.6246825-3.4509442C25.3668911 3.1397064 21.6596783.1548598 15.6755231.1548598 6.4090029.1548598 0 7.2100898 0 15.4298047c0 8.4140488 6.7567431 15.3918489 16.2152764 15.3918489 4.1698585 0 7.6457485-1.3557823 10.3097407-3.9155236l-2.2784542-3.2186545c-1.158126 1.1644849-4.1305487 2.6766452-7.7984518 2.6766452-6.6025279 0-11.1972342-4.9236305-11.1972342-11.0497017z"
            fill="#fff"
            fillRule="evenodd"
        />
    </svg>
)

export function customStyledComponent(Component) {
    return (style, options) => {
        function StyledComponent(props: { [x: string]: any; classes: any; className: string }) {
            const { classes, className, ...other } = props
            return <Component className={classNames(classes.root, className)} {...other} />
        }
        const styles = typeof style === 'function' ? theme => ({ root: style(theme) }) : { root: style }
        return withStyles(styles, options)(StyledComponent)
    }
}
