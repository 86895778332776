import { observable, makeAutoObservable } from 'mobx'
import { OrdreDeFabricationFilters } from './models'
import { Filters, FiltersPost, OrdreDeFabrication } from '../../../ordreDeFabrication/models'
import { LensTwoTone } from '@material-ui/icons'

class FiltersState {
    @observable filtersData: OrdreDeFabricationFilters = new OrdreDeFabricationFilters()
    @observable ordreDeFabricationsToDisplay: OrdreDeFabrication[] = []
    @observable ordreDeFabricationsTotal: OrdreDeFabrication[] = []
    @observable filtersShouldBeDisplayed: boolean = true
    @observable ordreDeFabricationsCount: number = 0
    @observable filters: Filters
    @observable favorite: FiltersPost
    @observable isExcel: boolean = false
    @observable isExcelAsn: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

let filtersState = new FiltersState()
export default filtersState
