import React, { Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { withStyles, createStyles } from '@material-ui/core'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { state as calendrierState } from './_calendrier'
import api from '../../../infrastructure/api'
import { showNotification } from '../../../infrastructure/notifications'
import { ReferenceProduit, QuantiteSemaine, StatutSemaine, Semaine, PlanifierExpedition } from '../../models'
import PlanningInitial from './_planningInitial'
import PlanningValide from './_planningValide'
import { t } from 'i18next'
import filterHelper from '../../../infrastructure/toolbar/filters/filterHelper'

type PlanningProps = {
    referenceProduit: ReferenceProduit
    fabricant: string
}

class PlanningState {
    @observable previousValue: string

    constructor() {
        makeAutoObservable(this)
    }
}
let state = new PlanningState()

@observer
class Planning extends Component<MuiProps & PlanningProps> {
    isCurrentWeek = (week: Semaine) =>
        week.annee == calendrierState.currentSemaine.annee && week.numero == calendrierState.currentSemaine.numero

    backgroundColor = (week: Semaine, index: number) => {
        if (this.isCurrentWeek(week)) return this.props.classes.innerInputCurrentWeek
        if (index % 2 == 0) return this.props.classes.innerInputEvenWeek
        return this.props.classes.innerInputOddWeek
    }

    onFocusQuantite = (element: HTMLElement, quantiteSemaine?: QuantiteSemaine) => {
        let targetElement = element as HTMLInputElement
        targetElement.select()
        state.previousValue =
            quantiteSemaine && quantiteSemaine.quantite != null ? quantiteSemaine.quantite.toString() : ''
    }

    onChangeQuantite = (element: HTMLElement, quantiteSemaine?: QuantiteSemaine) => {
        let targetElement = element as HTMLInputElement
        let [hasCorrectValue, value] = targetElement.value.tryParse()
        targetElement.value = !hasCorrectValue ? '' : value.toString()

        if (quantiteSemaine) quantiteSemaine.quantite = hasCorrectValue ? value : undefined
    }

    onBlurQuantite = async (
        element: HTMLElement,
        referenceProduit: ReferenceProduit,
        semaine: Semaine,
        isInitial: boolean,
    ) => {
        let targetElement = element as HTMLInputElement
        let newValue = targetElement.value || ''
        let [hasCorrectValue, value]: [boolean, number] = newValue.tryParse()
        let valueChanged = state.previousValue != newValue
        try {
            if (valueChanged && value >= 0) {
                await api.post<PlanifierExpedition>('ofProduit/planifierExpedition', {
                    OrdreDeFabricationNumero: referenceProduit.ordreDeFabricationNumero,
                    ProduitCode: referenceProduit.produitCode,
                    Semaine: semaine,
                    Quantite: hasCorrectValue ? value : null,
                    IsInitialPlanning: isInitial,
                })
            } else if (hasCorrectValue && value < 0) showNotification(t('Message.incorrectValue'), 'warning')
        } finally {
            if (valueChanged) {
                targetElement.value = ''
                await filterHelper.reloadReferenceProduit(
                    referenceProduit.ordreDeFabricationNumero,
                    referenceProduit.id,
                )
            }
        }
    }

    getQuantiteByWeekAndStatut = (
        quantiteSemaines: QuantiteSemaine[],
        week: Semaine,
        StatutSemaine: StatutSemaine,
    ): QuantiteSemaine | undefined => {
        return (
            quantiteSemaines &&
            quantiteSemaines.find(
                e =>
                    e.semaine.numero == week.numero &&
                    e.semaine.annee == week.annee &&
                    e.statutSemaine == StatutSemaine,
            )
        )
    }

    render() {
        let { referenceProduit, fabricant, classes } = this.props

        return (
            <div className={classes.tablePlanningQuantiteParSemaine}>
                {(!referenceProduit.fabricantValidatedInitial || !referenceProduit.celineValidatedInitial) && (
                    <PlanningInitial
                        referenceProduit={referenceProduit}
                        fabricantLabel={fabricant}
                        backgroundColor={this.backgroundColor}
                        onFocusQuantite={this.onFocusQuantite}
                        onChangeQuantite={this.onChangeQuantite}
                        onBlurQuantite={this.onBlurQuantite}
                        getQuantiteByWeekAndStatut={this.getQuantiteByWeekAndStatut}
                    />
                )}
                {!!referenceProduit.fabricantValidatedInitial &&
                    !!referenceProduit.celineValidatedInitial && (
                        <PlanningValide
                            referenceProduit={referenceProduit}
                            fabricantLabel={fabricant}
                            backgroundColor={this.backgroundColor}
                            onFocusQuantite={this.onFocusQuantite}
                            onChangeQuantite={this.onChangeQuantite}
                            onBlurQuantite={this.onBlurQuantite}
                            getQuantiteByWeekAndStatut={this.getQuantiteByWeekAndStatut}
                        />
                    )}
            </div>
        )
    }
}

const styles = theme =>
    createStyles({
        tablePlanningQuantiteParSemaine: {
            ...defaultStyles.flexColumn,
        },
        lineLabel: {
            width: '70px',
            fontWeight: 'bold',
            color: defaultColors.grey.dark.color,
        },
        lineTablePlanningQuantiteParSemaine: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        input: {
            margin: theme.spacing(),
        },
        innerInputCurrentWeek: {
            ...defaultStyles.input,
            backgroundColor: defaultColors.primary.main.color,
            color: defaultColors.primary.main.text,
        },
        innerInputEvenWeek: {
            ...defaultStyles.input,
            backgroundColor: defaultColors.grey.light.color,
            color: defaultColors.grey.light.text,
        },
        innerInputOddWeek: {
            ...defaultStyles.input,
        },
    })

export default withStyles(styles, muiOptions)(Planning)
