import { Produit, OrdreDeFabrication } from '../ordreDeFabrication/models'
import Guid from 'guid'

export enum Steps {
    Prepare = 0,
    Checking = 1,
    Created = 3,
}

export type AdvancedShippingNoticeView = {
    Id: Guid
    statut: string
    totalAExpedier: number
    totalRecu: number
    numero: number
    dateExpedition: Date
    fabricant: { code: string; label: string }
    ordreDeFabrications: OrdreDeFabrication[]
    referenceProduitIds: Guid[]
    username: string
}

export type AdvancedShippingNoticeViewFiltered = {
    Asn: AdvancedShippingNoticeView[]
    Total: number
}

export type DraftAdvancedShippingNotice = {
    id: Guid
    numero?: number
    totalPretAExpedier: number
    ofAsns: OfAsn[]
}

export type DetailsAdvancedShippingNotice = {
    id: Guid
    numero: number
    note: string
    statut: string
    magasinReception: MagasinReception
    magasinCode: string
    totalAExpedier: number
    dateExpedition: Date
    ofAsns: OfAsn[]
}

export type Saison = {
    code: string
    description: string
}

export type OfAsn = {
    id: Guid
    draftTotalPretAExpedier: number
    ordreDeFabricationNumber: string
    ordreDeFabricationSaison: Saison
    typeOrdreDeFabrication: string
    referenceProduitId: Guid
    referenceProduitStatut: string
    produit: Produit
    pretAExpedier: Line
    resteAPreparer: Line
    recu: Line
}

export type Line = {
    total: number
    quantites: QuantiteAsn[]
}

export type ProgrammerExpedition = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    Taille: string
    MagasinCode: string
    Quantite: number | null
}

export type DeclarerExpedition = {
    AdvancedShippingNoticeId: Guid
    Username: string
}

export type AjouterNote = {
    AdvancedShippingNoticeId: Guid
    Note: string
}

export type QuantiteAsn = {
    taille: string
    quantite: number | null
}

export type MagasinReception = {
    code: string
    fabricantCode: string
    label: string
    isPrincipal: boolean
}

export enum StatutAsn {
    Draft = 'Draft',
    NonRecu = 'NonRecu',
    PartiellementRecu = 'PartiellementRecu',
    CompletementRecu = 'CompletementRecu',
}
