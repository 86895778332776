import React, { Component } from 'react'
import { observable, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { createStyles, withStyles } from '@material-ui/core'
import { Typography, Fab, Button, TextField, FormControlLabel, Switch, Tooltip } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { showNotification } from '../../../infrastructure/notifications'
import api from '../../../infrastructure/api'
import { ToggleAlerteCommentaire, ModifierCommentaire, Commentaire } from '../../models'
import { toParisTimeZone } from '../../../infrastructure/time'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type LigneCommentaireProps = {
    ordreDeFabricationNumero: string
    produitCode: string
    comment: Commentaire
    loadComments: Function
}

class LigneCommentaireState {
    @observable previousValue: string
    @observable isEdit: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

@observer
class LigneCommentaire extends Component<MuiProps & LigneCommentaireProps> {
    state = new LigneCommentaireState()

    async updateCommentaire(comment: Commentaire) {
        try {
            if (comment.contenu.trim().length > 0) {
                await api.post<ModifierCommentaire>(`commentaire/update`, {
                    OrdreDeFabricationNumero: this.props.ordreDeFabricationNumero,
                    ProduitCode: this.props.produitCode,
                    Contenu: comment.contenu.trim(),
                    CommentaireId: comment.id,
                })
                showNotification(t('detailsOf.commentaires.MessageUpdateWithSuccess'), 'success')
            }
        } catch (e) {
            showNotification(e.message, 'error')
        } finally {
            this.props.loadComments(this.props.ordreDeFabricationNumero, this.props.produitCode)
            this.state.previousValue = ''
            this.state.isEdit = !this.state.isEdit
        }
    }

    async toggleUpdateCommentaire(comment: Commentaire) {
        this.state.previousValue = comment.contenu
        this.state.isEdit = !this.state.isEdit
    }

    async cancelUpdateCommentaire(comment: Commentaire) {
        comment.contenu = this.state.previousValue
        this.state.previousValue = ''
        this.state.isEdit = !this.state.isEdit
    }

    async toggleAlerteCommentaire(comment: Commentaire) {
        try {
            await api.post<ToggleAlerteCommentaire>(`commentaire/toggleAlert`, {
                OrdreDeFabricationNumero: this.props.ordreDeFabricationNumero,
                ProduitCode: this.props.produitCode,
                CommentaireId: comment.id,
                IsNewAlert: !comment.isAlert,
            })
            showNotification(t('detailsOf.commentaires.MessageToggleAlerteWithSuccess'), 'success')
        } catch (e) {
            showNotification(e.message, 'error')
        } finally {
            this.props.loadComments(this.props.ordreDeFabricationNumero, this.props.produitCode)
        }
    }

    render() {
        let { comment, classes } = this.props
        return (
            <div className={classes.root}>
                <div className={classes.headerComment}>
                    <div className={classes.leftHeaderComment}>
                        <Icons.MessageOutlined className={classes.infosComment} />
                        <Typography variant="subtitle1" className={classes.infosComment}>
                            {comment.userName.toUpperCase()}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infosCommentDate}>
                            {toParisTimeZone(comment.lastModificationDate)}
                        </Typography>
                        {comment.isSuspension && (
                            <Typography variant="subtitle1" className={classes.infosCommentSuspension}>
                                {t('detailsOf.commentaires.suspension')}
                            </Typography>
                        )}
                    </div>
                    <div className={classes.rightHeaderComment}>
                        {!this.state.isEdit && (
                            <div className={classes.actionButtons}>
                                <Tooltip
                                    key="updateCommentTooltip"
                                    title={t('detailsOf.commentaires.tooltip.updateComment')}>
                                    <Fab
                                        className={classes.buttonEdit}
                                        onClick={() => this.toggleUpdateCommentaire(comment)}>
                                        <Icons.Edit />
                                    </Fab>
                                </Tooltip>
                                <Tooltip
                                    key="changeAlertCommentTooltip"
                                    title={t('detailsOf.commentaires.tooltip.toggleAlert')}>
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={
                                            <Switch
                                                color="secondary"
                                                checked={comment.isAlert}
                                                onChange={() => this.toggleAlerteCommentaire(comment)}
                                            />
                                        }
                                        label=""
                                        labelPlacement="start"
                                    />
                                </Tooltip>
                            </div>
                        )}
                        {this.state.isEdit && (
                            <div className={classes.actionButtons}>
                                <Tooltip
                                    key="saveUpdateCommentTooltip"
                                    title={t('detailsOf.commentaires.tooltip.save')}>
                                    <div>
                                        <Fab
                                            className={classes.buttonValidate}
                                            onClick={() => this.updateCommentaire(comment)}
                                            disabled={comment.contenu.trim().length == 0}>
                                            <Icons.Check />
                                        </Fab>
                                    </div>
                                </Tooltip>
                                <Tooltip key="cancelCommentTooltip" title={t('detailsOf.commentaires.tooltip.cancel')}>
                                    <Fab
                                        className={classes.buttonCancel}
                                        onClick={() => this.cancelUpdateCommentaire(comment)}>
                                        <Icons.Clear />
                                    </Fab>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
                {!this.state.isEdit && (
                    <TextField
                        multiline
                        disabled
                        fullWidth
                        rows="3"
                        rowsMax="3"
                        value={comment.contenu}
                        inputProps={{
                            style: {
                                color: 'black',
                            },
                        }}
                        InputProps={{ classes: { disabled: classes.disabledInput } }}
                    />
                )}
                {this.state.isEdit && (
                    <TextField
                        multiline
                        fullWidth
                        rows="3"
                        rowsMax="3"
                        className={classes.textField}
                        label={t('detailsOf.commentaires.newCommentaire')}
                        value={comment.contenu}
                        onChange={event => {
                            comment.contenu = event.target.value
                        }}
                        inputProps={{
                            maxLength: 500,
                        }}
                    />
                )}
            </div>
        )
    }
}

let styles = theme =>
    createStyles({
        actionButtons: {
            ...defaultStyles.flexRow,
        },
        formControlLabel: {
            marginLeft: '5px',
            marginRight: '0px',
        },
        buttonEdit: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        buttonValidate: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonAction as CSSProperties),
        },
        buttonCancel: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonAnnulation as CSSProperties),
        },
        infosComment: {
            fontWeight: 'bold',
            color: defaultColors.primary.main.color,
            marginLeft: '6px',
        },
        infosCommentDate: {
            fontSize: 'small',
            color: defaultColors.primary.main.color,
            marginLeft: '6px',
        },
        infosCommentSuspension: {
            color: defaultColors.red.main.color,
            fontWeight: 'bold',
            marginLeft: '15%',
        },
        headerComment: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        leftHeaderComment: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            width: '60%',
        },
        rightHeaderComment: {
            ...defaultStyles.flexRow,
        },
        textField: {
            ...defaultStyles.textField,
        },
        disabledInput: {
            ...defaultStyles.disabledInput,
        },
    })

export default withStyles(styles, muiOptions)(LigneCommentaire)
