interface Array<T> {
    distinct(): T[]
    first(callbackfn: (value: T) => any): T
    first(): T
    take(count: number): T[]
}

interface String {
    contains(value: string): boolean
    startsWith(value: string): boolean
    tryParse(): [boolean, number]
    isNumber(): boolean
    pad(padding: number): string
    toLowerFirstChar(): string
}

interface Number {
    pad(padding: number): String
}

if (!Array.prototype.distinct) {
    Array.prototype.distinct = function distinct<T>(): T[] {
        return this.reduce((acc: T[], cur: T) => {
            if (acc.indexOf(cur) === -1) acc.push(cur)
            return acc
        }, [])
    }
}

if (!Array.prototype.first) {
    Array.prototype.first = function first<T>(callbackfn?: (value: T) => any): T[] {
        let result = callbackfn ? this.filter(callbackfn) : this
        return result.length ? result[0] : null
    }
}

if (!Array.prototype.take) {
    Array.prototype.take = function take<T>(count: number): T[] {
        return this.reduce((acc: T[], x: T) => (acc.length < count ? acc.concat(x) : acc), [])
    }
}

if (!String.prototype.contains) {
    String.prototype.contains = function contains(value: string): boolean {
        return this.indexOf(value) !== -1
    }
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function startsWith(value: string): boolean {
        return this.indexOf(value) === 0
    }
}

if (!String.prototype.tryParse) {
    String.prototype.tryParse = function tryParse(): [boolean, number] {
        return this !== null && this.trim().isNumber() ? [true, Number(this.trim())] : [false, 0]
    }
}

if (!String.prototype.isNumber) {
    String.prototype.isNumber = function isNumber(): boolean {
        return this !== null && this.length > 0 ? !isNaN(Number(this.trim())) : false
    }
}

if (!String.prototype.pad) {
    String.prototype.pad = function pad(padding: number): string {
        let result = this.toString()
        while (result.length < (padding || 2)) {
            result = '0' + result
        }
        return result
    }
}

String.prototype.toLowerFirstChar = function toLowerFirstChar(): string {
    if (this.length == 0) return ''
    let firstChar = this[0].toLowerCase()
    return this.replace(this[0], firstChar)
}

if (!Number.prototype.pad) {
    Number.prototype.pad = function pad(padding: number): String {
        let result = String(this)
        while (result.length < (padding || 2)) {
            result = '0' + result
        }
        return result
    }
}

if (!Object.assign) {
    function assign(target, firstSource) {
        if (target === undefined || target === null) {
            throw new TypeError('Cannot convert first argument to object');
        }

        var to = Object(target);
        for (var i = 1; i < arguments.length; i++) {
            var nextSource = arguments[i];
            if (nextSource === undefined || nextSource === null) {
                continue;
            }

            var keysArray = Object.keys(Object(nextSource));
            for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                var nextKey = keysArray[nextIndex];
                var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                if (desc !== undefined && desc.enumerable) {
                    to[nextKey] = nextSource[nextKey];
                }
            }
        }
        return to;
    }

    Object.defineProperty(Object, 'assign', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: assign
    })
}