import React, { Component } from 'react'
import { observer } from 'mobx-react'
import {
    MuiProps,
    muiOptions,
    defaultColors,
    defaultStyles,
    borderStyle,
} from '../../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Typography, Input } from '@material-ui/core'
import { QuantiteStatut, StatutSkus } from '../../models'
import { statutSkuState } from '../../statutSku'
import { t } from 'i18next'

type QuantitesProduitParStatutProps = {
    quantiteStatuts: QuantiteStatut[]
}

@observer
class QuantitesProduitParStatut extends Component<MuiProps & QuantitesProduitParStatutProps, {}> {
    constructor(props) {
        super(props)
    }

    getQuantiteStatutValue(statutSkuCode: string, quantiteStatuts?: QuantiteStatut[]): string {
        let quantiteStatut = quantiteStatuts && quantiteStatuts.first(x => x.statutSku == statutSkuCode)
        return quantiteStatut ? quantiteStatut.quantite.toString() : ''
    }

    isErrorResteAPlanifier = (statutSkuCode: string, quantiteStatut: string) => {
        return statutSkuCode == StatutSkus.ResteAPlanifier && +quantiteStatut != 0
    }

    render() {
        const { quantiteStatuts, classes } = this.props
        return (
            <section className={classes.sectionStatus}>
                {statutSkuState.itemsToShowByPlan.map((statutSku, index) => {
                    const quantiteStatut = this.getQuantiteStatutValue(statutSku.code, quantiteStatuts)
                    return (
                        <div key={index + ' quantiteParStatus'} className={classes.tableStatus}>
                            <Typography className={classes.statutLabel} variant="subtitle1">
                                {t('status.'.concat(statutSku.code.toLowerFirstChar()))}
                            </Typography>
                            <Input
                                value={quantiteStatut}
                                inputProps={{ maxLength: 5 }}
                                className={classes.input}
                                classes={{
                                    input: this.isErrorResteAPlanifier(statutSku.code, quantiteStatut)
                                        ? classes.innerInputRed
                                        : classes.innerInput,
                                }}
                                disabled
                            />
                        </div>
                    )
                })}
            </section>
        )
    }
}

const styles = theme =>
    createStyles({
        sectionStatus: {
            margin: '0 1rem',
            padding: '0 .5rem',
            borderLeft: borderStyle,
            borderRight: borderStyle,
            backgroundColor: defaultColors.grey.light.color,
            color: defaultColors.grey.light.text,
        },
        tableStatus: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        input: {
            margin: theme.spacing(),
        },
        innerInput: {
            ...defaultStyles.input,
        },
        innerInputRed: {
            ...defaultStyles.inputError,
        },
        statutLabel: {
            padding: '0 .5rem',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            color: defaultColors.grey.dark.color,
        },
    })

export default withStyles(styles, muiOptions)(QuantitesProduitParStatut)
