import _ from 'lodash'

export let delay = ms => new Promise(resolve => setTimeout(resolve, ms))

function defaultLoaderActivation(activate) {
    _.forEach(document.getElementsByClassName('loader'), x =>
        x.setAttribute('style', activate ? 'opacity: 1;' : 'transition: opacity 0.5s linear;'),
    )
}

export let loader = (() => {
    var num = 0
    var start = () => num++
    var end = () => num--
    var running = () => num > 0

    let displayProgressFor = (workPromise, toggleLoader?) => {
        toggleLoader = toggleLoader || defaultLoaderActivation
        let show = () => toggleLoader(true)
        let hide = () => toggleLoader(false)
        let delayHide = () => delay(1).then(() => (!running() ? hide() : null))

        start()
        workPromise.then(end).catch(end)

        delay(500).then(() => (running() ? show() : null))
        Promise.all([workPromise, delay(1000)])
            .then(delayHide)
            .catch(delayHide)
    }

    return { displayProgressFor: displayProgressFor }
})()

declare global {
    interface Window { token?: string; }
}

class AuthenticationTools {
    public getToken(): string {
      return window.token ?? "";
    }
  
    public setToken(token?: string ) {
      window.token = token;
    }
  }
  
  export const authenticationTools = new AuthenticationTools();