import React from 'react'
import { MuiProps, muiOptions } from '../../../materialUiThemeProvider'
import {
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    InputLabel,
    Input,
    createStyles,
    withStyles,
    WithStyles,
} from '@material-ui/core'
import { KeyValuePair } from '../models'
import { t } from 'i18next'

interface FilterSelectorProps extends WithStyles<typeof styles> {
    handleChange: (type: string, key: string) => void
    type: string
    typeValues: KeyValuePair
    selectedItems: string[]
}

class FilterSelector extends React.Component<FilterSelectorProps> {
    constructor(props) {
        super(props)
    }
    render(): React.ReactNode {
        let props = this.props as MuiProps & FilterSelectorProps

        let classes = props.classes
        let type = props.type
        let handleChange = props.handleChange
        let typeValues = props.typeValues
        let selectedItems = props.selectedItems

        return (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-checkbox" className={classes.typeMainLabel}>
                    {t('product.'.concat(type.toLowerFirstChar()))}
                </InputLabel>
                <Select
                    multiple
                    onChange={event => handleChange(type, (event.target.value as string)[0])}
                    name={type}
                    input={<Input id="select-multiple-checkbox" />}
                    value={[]}
                    className={classes.selectEmpty}>
                    <MenuItem value={type} disabled>
                        {t('product.'.concat(type.toLowerFirstChar()))}
                    </MenuItem>
                    {/* <Input
                        autoFocus
                        autoComplete='off'
                        placeholder='search'
                        type='text' id='search'
                        onChange={(e) => setSearchTerm(e.target.value)}
                    /> */}
                    {filterOptions(typeValues).map(x => (
                        <MenuItem value={x.key} key={x.key}>
                            <Checkbox
                                color="primary"
                                checked={selectedItems.length > 0 && selectedItems.indexOf(x.key) >= 0}
                            />
                            <ListItemText primary={x.value} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

let styles = () =>
    createStyles({
        formControl: {
            width: '80%',
            margin: '0% 2%',
        },
        typeMainLabel: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '85%',
            whiteSpace: 'nowrap',
        },
        typeSecondaryLabel: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        },
    })

export default withStyles(styles, muiOptions)(FilterSelector)
function filterOptions(typeValues: KeyValuePair) {
    let byValues = (a: { key: string; value: string }, b: { key: string; value: string }) =>
        a.value < b.value ? -1 : a.value > b.value ? 1 : 0

    var s = Object.keys(typeValues)
        .map(x => ({
            key: x,
            value: typeValues[x],
        }))
        .sort(byValues)

    return s
}
