import { t } from 'i18next'

export type ExportExcel<T> = {
    DataToIntegrate: T[]
    Traductions: { [key: string]: string }
}

export function getListeTraductions<T>(obj: T): { [key: string]: string } {
    let traductions: { [key: string]: string } = {}
    const objectKeys = Object.keys(obj as {}) as Array<keyof T>
    for (let key of objectKeys) {
        traductions[key.toString()] = t('exportExcel.shipment.'.concat(key.toString().toLowerFirstChar()))
    }
    return traductions
}
