import { observable, makeAutoObservable } from 'mobx'
import { StatutReferenceProduit } from './models'
import api from '../infrastructure/api'
import { delay } from '../infrastructure/helper'
import { getAuthToken } from '../infrastructure/authToken'

class StatutReferenceProduitState {
    @observable items: StatutReferenceProduit[] = []

    constructor() {
        makeAutoObservable(this)
    }
}

async function loadStatutReferenceProduits() {
    try {
        if (getAuthToken()) {
            statutReferenceProduitState.items = await api.get<StatutReferenceProduit[]>(
                'ofProduit/statutReferenceProduit',
            )
        } else {
            await delay(10)
            loadStatutReferenceProduits()
        }
    } catch (ex) {
        await delay(5000)
        loadStatutReferenceProduits()
    }
}

export let statutReferenceProduitState = new StatutReferenceProduitState()

loadStatutReferenceProduits()
