import React from 'react'
import { MuiProps, muiOptions } from '../../../materialUiThemeProvider'
import * as Icons from '@material-ui/icons'
import { createStyles, withStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment, { Moment } from 'moment'
import { t } from 'i18next'

type FilterDatePickerProps = {
    handleChange: (type: string, key: string) => void
    type: string
    date: Date
}

function FilterDatePicker({ handleChange, type, date, classes }: MuiProps & FilterDatePickerProps) {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                className={classes.DatePicker}
                label={t('product.'.concat(type.toLowerFirstChar()))}
                onChange={newDate => handleChange(type, moment(newDate as Moment).format(t('date.format.date')))}
                value={date}
                leftArrowIcon={<Icons.ChevronLeft />}
                rightArrowIcon={<Icons.ChevronRight />}
            />
        </MuiPickersUtilsProvider>
    )
}

let styles = () =>
    createStyles({
        DatePicker: {
            margin: '0% 5%',
            width: '50%',
        },
    })

export default withStyles(styles, muiOptions)(FilterDatePicker)
