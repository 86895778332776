import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import { observable, makeAutoObservable } from 'mobx'
import moment, { Moment } from 'moment'
import { createStyles, withStyles, IconButton, Paper, Typography, Tooltip } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Semaine } from '../../models'
import time from '../../../infrastructure/time'
import { t } from 'i18next'

const weekStep = 2
const numberOfPrevWeeks = 0
const numberOfNextWeeks = 9

type CalendrierProps = {}

class CalendrierState {
    @observable listOfWeeks: Semaine[] = []
    @observable
    currentSemaine: Semaine = {
        numero: time.now.isoWeek(),
        annee: time.now.year(),
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export let state = new CalendrierState()

@observer
class Calendrier extends Component<MuiProps & CalendrierProps, {}> {
    selectedDate: Moment
    startDate: Moment
    endDate: Moment

    constructor(props) {
        super(props)
        this.handleChangeOfSelectedDate('today')
    }

    refreshCurrentSemaine() {
        state.currentSemaine = {
            numero: time.now.isoWeek(),
            annee: time.now.year(),
        }
    }

    handleChangeOfStartAndEndDate() {
        this.startDate = moment(this.selectedDate)
            .subtract(numberOfPrevWeeks, 'weeks')
            .startOf('isoWeek')
        this.endDate = moment(this.selectedDate)
            .add(numberOfNextWeeks, 'weeks')
            .startOf('isoWeek')

        let listOfWeeks: Semaine[] = []
        for (let i = moment(this.startDate); i <= this.endDate; i.add(1, 'week')) {
            listOfWeeks.push({ annee: i.weekYear(), numero: i.isoWeek() })
        }
        state.listOfWeeks = listOfWeeks
    }

    handleChangeOfSelectedDate(typeOfChange: 'today' | 'backward' | 'forward') {
        this.refreshCurrentSemaine()

        switch (typeOfChange) {
            case 'today':
                this.selectedDate = time.now.startOf('isoWeek')
                this.handleChangeOfStartAndEndDate()
                break
            case 'backward':
                this.selectedDate.subtract(weekStep, 'weeks')
                this.handleChangeOfStartAndEndDate()
                break
            case 'forward':
                this.selectedDate.add(weekStep, 'weeks')
                this.handleChangeOfStartAndEndDate()
                break
            default:
                break
        }
    }

    isCurrentWeek(week: Semaine) {
        return week.annee == state.currentSemaine.annee && week.numero == state.currentSemaine.numero
    }

    backgroundColor(week: Semaine, index: number) {
        if (this.isCurrentWeek(week)) {
            return this.props.classes.currentWeek
        } else if (index % 2 == 0) {
            return this.props.classes.evenWeek
        } else {
            return this.props.classes.oddWeek
        }
    }

    render() {
        let { classes } = this.props

        return (
            <Fragment>
                <div className={classes.root}>
                    <IconButton
                        color="primary"
                        className={classes.button}
                        aria-label="today"
                        onClick={() => this.handleChangeOfSelectedDate('today')}
                        style={{ alignSelf: 'flex-end' }}>
                        <Icons.Today />
                    </IconButton>
                    <div className={classes.calendrier}>
                        <Typography variant="subtitle1">
                            {this.selectedDate.year() == this.endDate.year()
                                ? this.selectedDate.year()
                                : this.selectedDate.year() + ' / ' + this.endDate.year()}
                        </Typography>

                        <div className={classes.listOfWeeks}>
                            <IconButton
                                color="primary"
                                className={classes.button}
                                aria-label="backward"
                                onClick={() => this.handleChangeOfSelectedDate('backward')}>
                                <Icons.KeyboardArrowLeft />
                            </IconButton>
                            {state.listOfWeeks.map((week, index) => {
                                let date = time.now
                                    .year(week.annee)
                                    .isoWeek(week.numero)
                                    .day(5)
                                return (
                                    <Tooltip
                                        key={week.annee + ' Tooltip #' + week.numero}
                                        title={date.format(t('date.format.date'))}>
                                        <div>
                                            <Paper
                                                key={week.annee + ' week #' + week.numero}
                                                className={this.backgroundColor(week, index)}
                                                square={true}
                                                elevation={1}>
                                                <Typography variant="subtitle1">{'W' + week.numero}</Typography>
                                            </Paper>
                                        </div>
                                    </Tooltip>
                                )
                            })}
                            <IconButton
                                color="primary"
                                className={classes.button}
                                aria-label="forward"
                                onClick={() => this.handleChangeOfSelectedDate('forward')}>
                                <Icons.KeyboardArrowRight />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

let styles = theme =>
    createStyles({
        root: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-end',
        },
        button: {
            ...defaultStyles.button,
        },
        calendrier: {
            ...defaultStyles.flexColumn,
            justifyContent: 'center',
            alignItems: 'center',
        },
        listOfWeeks: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        currentWeek: {
            ...defaultStyles.week,
            backgroundColor: defaultColors.primary.main.color,
            color: defaultColors.primary.main.text,
        },
        evenWeek: {
            ...defaultStyles.week,
            backgroundColor: defaultColors.grey.main.color,
            color: defaultColors.grey.main.text,
        },
        oddWeek: {
            ...defaultStyles.week,
        },
    })

export default withStyles(styles, muiOptions)(Calendrier)
