import { OktaAuthOptions } from '@okta/okta-auth-js'

export const callback = 'authorization-code/callback'
const REDIRECT_URI = `${window.location.origin}/${callback}`

const getClientId = () => {
    if (window.location.origin.includes('dev')) {
        return process.env.OKTA_DEV_CLIENT_ID
    }
    if (window.location.origin.includes('int')) {
        return process.env.OKTA_INT_CLIENT_ID
    }
    if (window.location.origin.includes('rec')) {
        return process.env.OKTA_REC_CLIENT_ID
    }
    if (window.location.origin.includes('uat')) {
        return process.env.OKTA_UAT_CLIENT_ID
    }

    return process.env.OKTA_PROD_CLIENT_ID
}

const getIssuer = () => {
    if (window.location.origin.includes('dev')) {
        return process.env.OKTA_DEV_ISSUER
    }
    if (window.location.origin.includes('int')) {
        return process.env.OKTA_INT_ISSUER
    }
    if (window.location.origin.includes('rec')) {
        return process.env.OKTA_REC_ISSUER
    }
    if (window.location.origin.includes('uat')) {
        return process.env.OKTA_UAT_ISSUER
    }

    return process.env.OKTA_PROD_ISSUER    
}

export const oktaConfig: OktaAuthOptions = {
    clientId: getClientId(),
    issuer: getIssuer()!!,
    scopes: ['openid', 'profile', 'email'],
    redirectUri: REDIRECT_URI,
    pkce: true,
    tokenManager: {
        autoRenew: true,
        autoRemove: true,
        syncStorage: true,
    },
}
