import React, { Component, useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Snackbar as MuiSnackbar, SnackbarContent, IconButton } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../infrastructure/materialUiThemeProvider'
import { NotificationsStpre, Notif, state as notificationsState, NotificationsState } from './notifications'

const variantIcon = {
    success: Icons.CheckCircle,
    warning: Icons.Warning,
    error: Icons.Error,
    info: Icons.Info,
}

type SnackbarProps = {
    message?: string
    variant?: 'success' | 'warning' | 'error' | 'info'
}

type Props = MuiProps & SnackbarProps

const Snackbar = (props: Props) => {
    const { classes, message, variant } = props
    const Icon = variantIcon[variant]
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const NotifState = useContext(NotificationsStpre)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setIsOpen(false)
    }

    useEffect(() => {
        setIsOpen(true)
    }, [])

    return (
        <div>
            <MuiSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={isOpen}
                autoHideDuration={5000}
                onClose={handleClose}>
                <SnackbarContent
                    className={classes[variant]}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            <Icon className={classNames(classes.icon, classes.iconVariant)} />
                            {message}
                        </span>
                    }
                    action={
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={() => {
                                setIsOpen(false)
                            }}>
                            <Icons.Close className={classes.icon} />
                        </IconButton>
                    }
                />
            </MuiSnackbar>
        </div>
    )
}

let styles = theme => ({
    success: {
        backgroundColor: defaultColors.green.dark.color,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: defaultColors.amber.dark.color,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        ...defaultStyles.flexRow,
        alignItems: 'center',
    },
})

export default withStyles(styles, muiOptions)(Snackbar)
