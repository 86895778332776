import React from 'react'
import { Chip, Tooltip } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../infrastructure/materialUiThemeProvider'
import { StatutRefProduit } from '../ordreDeFabrication/models'
import classNames from 'classnames'
import { t } from 'i18next'

type OfStatutChipProps = {
    className?: any
    label: React.ReactNode
    statut: string
    disableTooltip?: boolean
}

let OfStatutChip = ({ classes, className, label, statut, disableTooltip }: MuiProps & OfStatutChipProps) => {
    let statutClass = null
    switch (statut) {
        case StatutRefProduit.Created:
            statutClass = classes.created
            break
        case StatutRefProduit.Started:
            statutClass = classes.started
            break
        case StatutRefProduit.Suspended:
            statutClass = classes.suspended
            break
        case StatutRefProduit.Closed:
            statutClass = classes.closed
            break
        case StatutRefProduit.Alert:
            statutClass = classes.alert
            break
        default:
            break
    }

    let tooltipValue = t('statutOf.'.concat(statut.toLowerFirstChar()))
    return (
        <Tooltip title={!!disableTooltip ? '' : tooltipValue}>
            <Chip className={classNames(classes.chip, className, statutClass)} label={label} />
        </Tooltip>
    )
}

let styles = theme =>
    createStyles({
        chip: {
            ...defaultStyles.chipNumeroOf,
        },
        created: {
            backgroundColor: defaultColors.blue.main.color,
            color: defaultColors.blue.dark.text,
        },
        started: {
            backgroundColor: defaultColors.green.main.color,
            color: defaultColors.green.dark.text,
        },
        suspended: {
            backgroundColor: defaultColors.amber.dark.color,
            color: 'white',
        },
        closed: {
            backgroundColor: defaultColors.grey.dark.color,
            color: 'white',
        },
        alert: {
            backgroundColor: defaultColors.red.dark.color,
            color: 'white',
        },
    })

export default withStyles(styles, muiOptions)(OfStatutChip)
