import moment, { Moment } from 'moment-timezone'
import { t } from 'i18next'
import api from './api'
import { getAuthToken } from './authToken'

const refreshServerEveryMs = 1000 * 60 * 15
const refreshEveryMs = 100

let lastNow: Moment | null = null
let now: Moment = moment()

export let toParisTimeZone = (date: Date): string =>
    moment(date)
        .tz('Europe/Paris')
        .format(t('date.format.dateTime'))
        .toString()

async function getServerDate() {
    if (getAuthToken()) {
        var result = await api.get<{ now: string }>('ping/date')
        return moment(result.now)
    }

    return moment(moment.now())
}

async function refresh() {
    try {
        if (lastNow === null || now.diff(lastNow) > refreshServerEveryMs) {
            lastNow = now = await getServerDate()
        } else {
            now = now.add(refreshEveryMs, 'ms')
        }
    } catch (ex) {
        console.error(ex)
    }
    setTimeout(refresh, refreshEveryMs)
}

refresh()

export default {
    get now() {
        return now.clone()
    },
}
