import React from 'react'
import { withStyles, createStyles, Typography, Tooltip } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { Saison } from '../models'
import OfStatutChip from '../../common/ofStatutChip'
import { t } from 'i18next'

type ShortProductHeaderProps = {
    numero: string
    statut: string
    fabricantLabel: string
    saison: Saison
    typeOrdreDeFabrication?: string
    groupage: string | null
    style?: any
}

function ShortProductHeader({ classes, style = { width: '100%' }, ...props }: MuiProps & ShortProductHeaderProps) {
    return (
        <section className={classes.shortProductHeader} style={style}>
            <OfStatutChip className={classes.numero} label={props.numero} statut={props.statut} />
            <div className={classes.leftHeader}>
                <div>
                    {!!props.fabricantLabel && (
                        <Tooltip title={t('product.manufacturer')}>
                            <Typography className={classes.headerLabel} variant="subtitle1">
                                {props.fabricantLabel}
                            </Typography>
                        </Tooltip>
                    )}
                    {!!props.saison && (
                        <Tooltip title={t('product.season')}>
                            <Typography className={classes.headerLabel} variant="subtitle1">
                                {props.saison.description}
                            </Typography>
                        </Tooltip>
                    )}
                </div>
                <div>
                    {!!props.typeOrdreDeFabrication && (
                        <Tooltip title={t('product.typeWO')}>
                            <Typography className={classes.headerLabel} variant="subtitle1">
                                {t('typeOf.'.concat(props.typeOrdreDeFabrication.toLowerCase()))}
                            </Typography>
                        </Tooltip>
                    )}
                    {!!props.groupage && (
                        <Tooltip title={t('product.group')}>
                            <Typography className={classes.headerLabel} variant="subtitle1">
                                {props.groupage}
                            </Typography>
                        </Tooltip>
                    )}
                </div>
            </div>
        </section>
    )
}

let styles = theme =>
    createStyles({
        shortProductHeader: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        leftHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
        },
        numero: {
            width: 70,
            minWidth: 70,
            marginTop: 5,
            marginLeft: 40,
            marginRight: 26,
        },
        headerLabel: {
            margin: '.3em .5em',
            whiteSpace: 'nowrap',
        },
    })

export default withStyles(styles, muiOptions)(ShortProductHeader)
