import React, { Fragment } from 'react'
import { LastCommentaireReferenceProduit } from '../../models'
import { MuiProps, muiOptions, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { createStyles, withStyles, Badge, Tooltip } from '@material-ui/core'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { toParisTimeZone } from '../../../infrastructure/time'
import { t } from 'i18next'

type BadgeCommentaireProps = {
    commentaireReferenceProduit: LastCommentaireReferenceProduit | undefined
}

function BadgeCommentaire({ commentaireReferenceProduit, classes }: MuiProps & BadgeCommentaireProps) {
    const lastComment = commentaireReferenceProduit ? commentaireReferenceProduit.lastCommentaire : undefined
    const hasAlert = commentaireReferenceProduit ? commentaireReferenceProduit.hasAlert : false
    const nbComments = commentaireReferenceProduit ? commentaireReferenceProduit.countCommentaire : 0
    const badgeColor = (nbComments as number) > 0 ? (hasAlert ? 'secondary' : undefined) : 'primary'
    const tooltipText = lastComment
        ? t('detailsOf.commentaires.tooltip.lastComment') +
          ' - ' +
          lastComment.userName.toUpperCase() +
          ' ' +
          toParisTimeZone(lastComment.lastModificationDate) +
          ': ' +
          lastComment.contenu
        : ''

    return (
        <Fragment>
            <Tooltip title={tooltipText}>
                <Badge
                    overlap="rectangular"
                    badgeContent={nbComments}
                    color={badgeColor}
                    classes={{ badge: (nbComments as number) > 0 && !hasAlert ? classes.blueBackground : undefined }}>
                    <QuestionAnswerIcon />
                </Badge>
            </Tooltip>
        </Fragment>
    )
}

let styles = theme =>
    createStyles({
        blueBackground: {
            backgroundColor: defaultColors.blue.main.color,
            color: theme.palette.common.white,
        },
    })

export default withStyles(styles, muiOptions)(BadgeCommentaire)
