import React from 'react'
import { Chip, Tooltip } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { StatutAsn } from '../models'
import classNames from 'classnames'
import { t } from 'i18next'

type AsnStatutChipProps = {
    className?: any
    label: React.ReactNode
    statut: string
    disableTooltip?: boolean
}

let AsnStatutChip = ({ classes, className, label, statut, disableTooltip }: MuiProps & AsnStatutChipProps) => {
    let statutClass = null
    switch (statut) {
        case StatutAsn.Draft:
            statutClass = classes.draft
            break
        case StatutAsn.NonRecu:
            statutClass = classes.nonRecu
            break
        case StatutAsn.PartiellementRecu:
            statutClass = classes.partiellementRecu
            break
        case StatutAsn.CompletementRecu:
            statutClass = classes.completementRecu
            break
        default:
            break
    }

    let tooltipValue = t('asn.statut.'.concat(statut.toLowerFirstChar()))
    return (
        <Tooltip title={!!disableTooltip ? '' : tooltipValue} placement="top">
            <Chip className={classNames(classes.chip, className, statutClass)} label={label} />
        </Tooltip>
    )
}

let styles = theme =>
    createStyles({
        chip: {
            ...defaultStyles.chipNumeroOf,
        },
        nonRecu: {
            backgroundColor: defaultColors.blue.main.color,
            color: defaultColors.blue.dark.text,
        },
        completementRecu: {
            backgroundColor: defaultColors.green.main.color,
            color: defaultColors.green.dark.text,
        },
        draft: {
            backgroundColor: defaultColors.grey.dark.color,
            color: 'white',
        },
        partiellementRecu: {
            backgroundColor: defaultColors.red.dark.color,
            color: 'white',
        },
    })

export default withStyles(styles, muiOptions)(AsnStatutChip)
